import React, { useState, useEffect } from "react";
import IBackArrow from "../../../assets/back-arrow.svg";
import IProfileImage from "../../../assets/profile-icon.svg";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  SideNavbar,
  TopHeader,
  PatientName,
  ProfileWrapper,
  PatientGender,
  PatientDob,
  ProfileIcon,
  PhoneNumber,
  ArrowBackImage,
  PatientEmail,
} from "./style.components";
import { useSelector } from "react-redux";
import { getPatientProfileAction } from "../../../store/actions/patients.action";
import { useAppDispatch } from "../../../store/store";
import moment from "moment";
import { Box } from "@mui/material";
// @ts-ignore
import { ShimmerCategoryItem } from "react-shimmer-effects";

const SideNav = () => {
  const navigate = useNavigate();
  let [searchParams] = useSearchParams();
  const patientId = searchParams.get("patient_id") || "";
  const dispatch = useAppDispatch();
  const [loading, setLoading] = useState<boolean>(false);

  const getSearchParam = (paramName: string) => {
    let paramValue = searchParams.get(paramName);
    return paramValue;
  };

  const [selectedPatientId, setSelectedPatientId] = useState(
    getSearchParam("name") || ""
  );
  const patientDetails = useSelector(
    (state: any) => state?.patientProfile?.patientProfile
  );

  useEffect(() => {
    if (patientId) {
      setSelectedPatientId(patientId);
    }
  }, [navigate, searchParams]);

  useEffect(() => {
    if (selectedPatientId) {
      fetchPatientData();
    }
  }, [selectedPatientId]);

  const fetchPatientData = async () => {
    try {
      if (selectedPatientId) {
        setLoading(true);
        await dispatch(getPatientProfileAction(selectedPatientId));
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <SideNavbar>
      <TopHeader onClick={() => navigate("/home")}>
        <ArrowBackImage src={IBackArrow} alt="back-arrow" />
        Home
      </TopHeader>
      {!loading ? (
        <>
          {" "}
          <ProfileWrapper>
            <ProfileIcon src={IProfileImage} alt="Profile Icon" />
            <PhoneNumber title={patientDetails?.phone_number}>
              {patientDetails?.phone_number}
            </PhoneNumber>
          </ProfileWrapper>
          <PatientName title={patientDetails?.name}>
            {patientDetails?.name}
          </PatientName>
          <PatientEmail title={patientDetails?.email}>
            {patientDetails?.email}
          </PatientEmail>
          <PatientGender title={patientDetails?.gender}>
            {patientDetails?.gender}
          </PatientGender>
          <PatientDob>
            {patientDetails?.dob &&
              moment(patientDetails.dob).format("DD-MMM-YYYY")}
          </PatientDob>{" "}
        </>
      ) : (
        <Box m={1}>
          <ShimmerCategoryItem
            hasImage
            imageType="circular"
            imageWidth={60}
            imageHeight={60}
            text
            cta
          />
        </Box>
      )}
    </SideNavbar>
  );
};

export default SideNav;
