import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import { Alert, CircularProgress, IconButton, Snackbar } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";

import ComplianceRoot from "./ComplianceRoot";

import logoWhite from "../assets/logo-horizontal-white.svg";
import labLoginIcon from "../assets/lab-login-icon.svg";
import resendIcon from "../assets/resend-icon.svg";
import EmailIcon from "../assets/mail-icon.svg";
import MobileIcon from "../assets/mobile-icon.svg";
import BlackRightArrow from "../assets/black-right-arrow-icon.svg";
import IBackArrow from "../assets/back-arrow-blue.svg";

import {
  API_ENDPOINT,
  API_KEY,
  COLOR_ERROR,
  COLOR_PRIMARY,
  COLOR_VARIANT,
  COOKIE_EXPIRY_DAYS,
  DEVICE,
  FONT_FAMILY_GENERAL,
} from "../utilities/constants";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import { setShowStatus, unsetShowStatus } from "../store/slices/statusSlice";
import { Checkbox } from "@mui/material";

const LoginContainer = styled.div({
  backgroundColor: "#0099CC",
  height: "100vh",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  fontFamily: FONT_FAMILY_GENERAL,
});

const LoginLogo = styled.div`
  margin-bottom: 60px;
  @media ${DEVICE.laptopL} {
    margin-bottom: 30px;
    img {
      width: 90%;
    }
  }
`;
const LoginForm = styled.div`
  display: flex;
  flex-direction: row;
  width: 70%;
  border-radius: 12px;
  overflow: hidden;
  @media ${DEVICE.laptopL} {
    justify-content: center;
    width: 90%;
  }
`;

const LoginFormLeft = styled.div`
  flex-basis: 55%;
  background-color: #ebf7fb;
  display: flex;
  flex-direction: column;
  padding: 50px;
  align-items: flex-start;
  @media ${DEVICE.tablet} {
    display: none;
  }
`;

const ImageBanner = styled.div({
  backgroundColor: "#EBF7FB",
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const ImageBannerSd = styled.div`
  flex-direction: row;
  align-items: center;
  margin-bottom: 20px;
  display: none;
  @media ${DEVICE.tablet} {
    display: flex;
  }
`;

const WelcomeMessage = styled.div({
  color: "#0099CC",
  fontSize: "20px",
  textAlign: "left",
});

const LoginFormRight = styled.div`
  flex-basis: 45%;
  background-color: #ffffff;
  padding: 40px;
  @media ${DEVICE.tablet} {
    border-radius: 12px;
  }
`;

const FormMessage = styled.div({
  color: "#000000",
  display: "flex",
  alignItems: "center",
});

const FormFieldSection = styled.div({
  display: "flex",
  flexDirection: "column",
});

const FormLabel = styled.div({
  color: "#808080",
  fontSize: "16px",
  textAlign: "left",
});

const FormInput = styled.div({
  color: "#808080",
  fontSize: "16px",
});

const CustomInput = styled.input({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flexGrow: 1,
  paddingLeft: "10px",
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});

const CustomSelect = styled.select({
  fontFamily: FONT_FAMILY_GENERAL,
  backgroundColor: "#EBF7FB",
  fontSize: "16px",
  color: "#000000",
  border: "none",
  borderRadius: "8px",
  height: "44px",
  flexGrow: 1,
  minWidth: "50px",
  maxWidth: "100px",
  paddingLeft: "10px",
  marginRight: "10px",
  option: {
    width: "30px",
  },
  [`@media ${DEVICE.mobileL}`]: {
    fontSize: "12px",
  },
});

const ActionText = styled.div({
  color: "#7ABA56",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  flexGrow: 1,
  ":hover": {
    textDecoration: "underline",
  },
});

const CustomButton = styled.button({
  backgroundColor: COLOR_VARIANT,
  border: "none",
  textDecoration: "none",
  height: "53px",
  width: "100%",
  cursor: "pointer",
  color: "#FFF",
  borderRadius: "8px",
  fontSize: "16px",
  "&:disabled": {
    backgroundColor: "#cccccc",
    color: "#666666",
    cursor: "not-allowed",
  },
});

const TextDescription = styled.div({
  color: "#808080",
  fontSize: "16px",
  textAlign: "left",
});

const CtaLink = styled.a({
  color: "#0099CC",
  fontSize: "16px",
  textDecoration: "none",
});

const FormFieldRow = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

const ErrorMessage = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  color: COLOR_ERROR,
});

const TCText = styled.div({
  textAlign: "left",
  fontSize: "13px",
  color: "#808080",
  ".MuiCheckbox-colorPrimary svg": {
    fill: COLOR_PRIMARY,
  },
});
const TCLink = styled.a({
  textDecoration: "none",
  fontSize: "13px",
  color: COLOR_PRIMARY,
  marginLeft: "3px",
});

export const LoginByContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const LoginByText = styled.div`
  font-size: 16px;
  color: #000000;
  margin-bottom: 40px;
`;

export const MobileIconImg = styled.img``;

export const EmailIconImg = styled.img``;
export const InfoContainer = styled("div")({
  backgroundColor: "#EBF7FB",
  border: "2px solid #0099CC",
  borderRadius: "8px",
  padding: "18px 16px",
  margin: "12px 0",
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  cursor: "pointer",
  maxWidth: "520px",
  height: "32px",
});

export const IconTextContainer = styled("div")({
  display: "flex",
  alignItems: "center",
  gap: "10px",
});

export const BackButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 40px;
  cursor: pointer;
`;
export const BackArrowImg = styled.img`
  width: 32px;
  height: 32px;
`;
export const BlackRightArrowImg = styled.img``;
export const BackText = styled.div`
  color: #000000;
  font-size: 16px;
`;

const Login = () => {
  const [cookies, setCookie, removeCookie] = useCookies();
  const [mobileNumber, setMobileNumber] = useState<string | null>();
  const [email, setEmail] = useState<string | null>();

  const [countryCode, setCountryCode] = useState<string>("+91");
  const [otp, setOtp] = useState("");
  const [otpEmail, setOtpEmail] = useState("");
  const [isOtpSent, setIsOtpSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryIcon, setSelectedCountryIcon] = useState("");
  const [isTACchecked, setIsTACchecked] = useState(false);
  const [type, setType] = useState("");
  const [showLoginForm, setShowLoginForm] = useState(false);
  const [showComplianceRoot, setShowComplianceRoot] = useState(false);
  const [isCountrySelected, setIsCountrySelected] = useState(false);
  const [validationToken, setValidationToken] = useState("");
  const [supportedCountries, setSupportedCountries] = useState([]);

  const statusReducer = useSelector((state: any) => state.status);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    if (cookies["access_token"]) {
      navigate("/home");
    }
  }, []);

  useEffect(() => {
    // if (!cookies["countryData"]) {
    //   navigate("/compliance");
    // }
  }, []);

  useEffect(() => {
    try {
      const countryData = cookies.countryData ? cookies.countryData : null;
      if (countryData) {
        setSelectedCountry(countryData.code);
        setSelectedCountryIcon(countryData.icon);
      }
    } catch (error) {
      console.error("Error parsing countryData cookie:", error);
    }
  }, [cookies.countryData]);

  const generateOtpHandler = async () => {
    if (!mobileNumber && !email) {
      return;
    }
    dispatch(
      setShowStatus({
        message: "Sending you OTP...",
        severity: "info",
        autoHide: "no",
      })
    );
    let payload = {};
    if (mobileNumber && type === "phone") {
      payload = {
        auth_id: countryCode + "" + mobileNumber,
        auth_type: "phone",
        source: "login",
      };
    } else if (email && type === "email") {
      payload = {
        auth_id: `${email}`,
        auth_type: "email",
        source: "login",
      };
    }

    const config = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-KEY": API_KEY,
      },
    };

    await fetch(API_ENDPOINT.OTP_GENERATE, {
      method: "POST",
      ...config,
      body: JSON.stringify(payload),
    })
      .then((response) => response.json())
      .then((response) => {
        console.log(JSON.stringify(response, null, 2));
        if (response?.status == "success") {
          dispatch(
            setShowStatus({
              message: "OTP sent successfully",
              severity: "success",
            })
          );
          setIsOtpSent(true);
        } else {
          dispatch(
            setShowStatus({
              message: response?.message || "Something went wrong",
              severity: "error",
              autoHide: "no",
            })
          );
          setMobileNumber(null);
          setEmail(null);
        }
      })
      .catch((error) => {
        console.log("Error in catch: ", JSON.stringify(error, null, 2));

        dispatch(
          setShowStatus({
            message: "Something went wrong!",
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const getAccessToken = async (validationAccessToken: any,countryCodeData?:any) => {
    dispatch(
      setShowStatus({
        message: "Accessing Token ...",
        severity: "info",
        autoHide: "no",
      })
    );
    
    const countryData = {
      country: countryCodeData
    };

    await fetch(API_ENDPOINT.ACCESS_TOKEN, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Bearer ${validationAccessToken || validationToken}`,
      },
      body: JSON.stringify(countryData),
    })
      .then((res1) => res1.json())
      .then((tokenData) => {
        if (
          tokenData &&
          tokenData?.practitioner &&
          tokenData.practitioner?.access_token
        ) {
          const { access_token } = tokenData.practitioner;

          dispatch(unsetShowStatus());
          let maxAge = 60 * 60 * 24 * COOKIE_EXPIRY_DAYS;
          setCookie("access_token", access_token, {
            path: "/",
            maxAge: maxAge,
          });
          setCookie("direct_link", JSON.stringify(true) , {
            path: "/",
            maxAge: maxAge,
          });          
          setShowLoginForm(false);
          setShowComplianceRoot(false);
          navigate("/home");
          localStorage.removeItem("isNewAlreadyLoaded");

        } else if (
          tokenData?.status == "error" ||
          tokenData?.status == "failed"
        ) {
          dispatch(
            setShowStatus({
              message: tokenData?.message || "Something went wrong",
              severity: "error",
              autoHide: "no",
            })
          );
          setShowLoginForm(true);
          setShowComplianceRoot(false);
          setSelectedCountry("");
        setSelectedCountryIcon("");
        }
      })
      .catch((err) => {
        console.log("Error in catch: ", JSON.stringify(err, null, 2));

        dispatch(
          setShowStatus({
            message: "Something went wrong!",
            severity: "error",
            autoHide: "no",
          })
        );
        setShowLoginForm(true);

      });
  };

  const verifyOtpHandler = async () => {
    dispatch(
      setShowStatus({
        message: "Validating OTP...",
        severity: "info",
        autoHide: "no",
      })
    );

    let payload =
      type === "phone"
        ? {
            auth_id: `${countryCode}${mobileNumber}`,
            auth_type: "phone",
            source: "login",
            otp: otp,
          }
        : {
            auth_id: email,
            auth_type: "email",
            source: "login",
            otp: otpEmail,
          };

    await fetch(API_ENDPOINT.OTP_VELIDATE, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "X-API-KEY": API_KEY,
      },
      body: JSON.stringify(payload),
    })
      .then((res) => res.json())
      .then(async (data) => {
        if (
          data?.status == "success" &&
          data?.result &&
          data.result?.validation_token
        ) {

          dispatch(unsetShowStatus());

if(data.result.datastores.length > 1){
  setShowComplianceRoot(true);
  setShowLoginForm(false);
  setValidationToken(data.result?.validation_token);
  setSupportedCountries(data.result?.datastores);
  setIsCountrySelected(false);
}else{
  onCountryListItemClick(data.result?.datastores[0])
  getAccessToken(data.result?.validation_token,data.result?.datastores[0]?.code);
}
        } else if (data?.status == "error" || data?.status == "failed") {
          dispatch(
            setShowStatus({
              message: data?.message || "Something went wrong",
              severity: "error",
              autoHide: "no",
            })
          );
        } else {
          dispatch(
            setShowStatus({
              message: "Something went wrong",
              severity: "error",
              autoHide: "no",
            })
          );
        }
      })
      .catch((err) => {
        console.log("Error in catch: ", JSON.stringify(err, null, 2));

        dispatch(
          setShowStatus({
            message: "Something went wrong!",
            severity: "error",
            autoHide: "no",
          })
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleLoginTypeSelection = (selectedType: any) => {
    setType(selectedType);
    if (selectedType == "phone") {
      // setShowComplianceRoot(true);
      setShowLoginForm(true);
      setShowComplianceRoot(false);

    } else {
      setShowLoginForm(true);
      setShowComplianceRoot(false);
    }
  };

  const handleContrySlection = (data: any) => {
    setIsCountrySelected(data.code);
      setTimeout(() => {
      getAccessToken("",data.code);
      }, 1000);
  };

  const handleBackToLoginTypeSelection = () => {
    removeCookie("countryData");
    setSelectedCountry("");
        setSelectedCountryIcon("");
    setShowLoginForm(false);
    setShowComplianceRoot(false);
    setType("");
    setIsTACchecked(false);
    setEmail('')
    setMobileNumber('')
    setOtp('')
    setOtpEmail('')
    setIsOtpSent(false);
  };

  const onCountryListItemClick = async (data:any) => {
    const serializedData = JSON.stringify(data);
    const maxAge = 60 * 60 * 24 * COOKIE_EXPIRY_DAYS;
    setCookie('countryData', serializedData, { path: '/',maxAge: maxAge, });
  };

  return (
    <>
      <LoginContainer>
        <LoginLogo>
          <img src={logoWhite} />
        </LoginLogo>
        <LoginForm>
          <LoginFormLeft>
            <ImageBanner>
              <img height={128} src={labLoginIcon} />
              <HorizontalSep></HorizontalSep>
              <WelcomeMessage>
                Welcome, <br />
                <span style={{ fontSize: "28px" }}>Lab Owner</span>
              </WelcomeMessage>
            </ImageBanner>
            <VerticalSep></VerticalSep>
            <TextDescription>
              Heme Health is an integrated health platform that allows
              physicians and carers to securely access patient information,
              following appropriate consent. It helps labs simplify their daily
              workflows like sending reports to patients, replacing reports on
              the go, viewing the historical reports and more.
            </TextDescription>
            <VerticalSep></VerticalSep>
            <CtaLink target={"_blank"} href="https://www.hemehealth.io">
              Visit us at <b>HemeHealth.io</b>
            </CtaLink>
          </LoginFormLeft>
          <LoginFormRight>
            <ImageBannerSd>
              <img height={88} src={labLoginIcon} />
              <HorizontalSep></HorizontalSep>
              <WelcomeMessage>
                Welcome, <br />
                <span style={{ fontSize: "28px" }}>Lab Owner</span>
              </WelcomeMessage>
            </ImageBannerSd>

            {showLoginForm ? (
              <>
                {/* <img
              src={selectedCountryIcon}
              alt="selected_country"
              style={{ height: 20, marginRight: 20 }}
            />
            <VerticalSep size={10}></VerticalSep>
            <FormMessage>
              Enter below credentials to login.{" "}
              <div style={{ marginLeft: "20px" }}>
                {isLoading ? (
                  <CircularProgress
                    size={18}
                    style={{ color: COLOR_VARIANT }}
                  />
                ) : null}
              </div>
            </FormMessage> */}

                <VerticalSep size={30}></VerticalSep>
                <BackButtonContainer onClick={handleBackToLoginTypeSelection}>
                  <BackArrowImg src={IBackArrow} />
                  <BackText>{`Back to Login`}</BackText>
                 {selectedCountryIcon && <img
                  src={selectedCountryIcon}
                  alt="selected_country"
                  style={{ height: 20, marginRight: 20,marginLeft:"10px" }}
                />}
                </BackButtonContainer>
               {!isOtpSent ?<>
                <FormFieldSection>
                  <FormLabel>
                    {type === "phone" ? "Mobile no." : "E-mail"}
                  </FormLabel>
                  <VerticalSep size={10}></VerticalSep>
                  <FormFieldRow>
                    {type === "phone" && (
                      <>
                        <CustomSelect
                          value={countryCode}
                          onChange={(event) =>
                            setCountryCode(event.target.value)
                          }
                        >
                          <option value="+91">+91</option>
                          <option value="+44">+44</option>
                          <option value="+212">+212</option>
                        </CustomSelect>
                        <CustomInput
                          value={mobileNumber!}
                          onChange={(event) =>
                            setMobileNumber(event.target.value)
                          }
                          type={"number"}
                          onWheel={(e: any) => e.target.blur()}
                        ></CustomInput>
                      </>
                    )}

                    {type === "email" && (
                      <CustomInput
                        value={email!}
                        onChange={(e) => setEmail(e.target.value)}
                        type="email"
                      />
                    )}
                  </FormFieldRow>
                  <FormFieldRow style={{width:'250px',margin:'40px auto'}}>
                  <CustomButton
                    onClick={generateOtpHandler}
                    value="Login"
                    type="button"
                  >
                    Send OTP
                  </CustomButton>
                  </FormFieldRow>
                </FormFieldSection>
                </>:
<>
                <FormFieldSection>
                  <FormLabel>Enter OTP</FormLabel>
                  <VerticalSep size={10}></VerticalSep>
                  <FormFieldRow>
                    <CustomInput
                      value={type === "phone" ? otp : otpEmail}
                      onChange={(e) =>
                        type === "phone"
                          ? setOtp(e.target.value)
                          : setOtpEmail(e.target.value)
                      }
                      type="number"
                    />

                    {/* <CustomInput
                      value={otp}
                      onChange={(event) => setOtp(event.target.value)}
                      type={"number"}
                    ></CustomInput> */}
                    <HorizontalSep size={10}></HorizontalSep>
                    {isOtpSent && (
                      <ActionText onClick={generateOtpHandler}>
                        {" "}
                        <img
                          src={resendIcon}
                          style={{ marginRight: "4px" }}
                        />{" "}
                        Resend
                      </ActionText>
                    )}
                  </FormFieldRow>
                </FormFieldSection>
                <VerticalSep size={20}></VerticalSep>
                <TCText>
                  <Checkbox
                    checked={isTACchecked}
                    onChange={() => setIsTACchecked(!isTACchecked)}
                  />
                  By logging in you agree to our
                  <TCLink href="terms-conditions" target={"_blank"}>
                    Terms and Conditions
                  </TCLink>
                </TCText>
                <VerticalSep size={20}></VerticalSep>
                <FormFieldSection>
                  <CustomButton
                    onClick={verifyOtpHandler}
                    value="Login"
                    type="button"
                    disabled={!isTACchecked}
                  >
                    Login
                  </CustomButton>
                </FormFieldSection>
                </>}
              </>
            ) : (
              <>
                {!showComplianceRoot && (
                  <LoginByContainer>
                    <LoginByText>Login by</LoginByText>
                    <InfoContainer
                      onClick={() => handleLoginTypeSelection("phone")}
                    >
                      <IconTextContainer>
                        <MobileIconImg src={MobileIcon} />
                        <span>Mobile Number</span>
                      </IconTextContainer>
                      <IconButton>
                        <BlackRightArrowImg src={BlackRightArrow} />
                      </IconButton>
                    </InfoContainer>
                    <InfoContainer
                      onClick={() => handleLoginTypeSelection("email")}
                    >
                      <IconTextContainer>
                        <EmailIconImg src={EmailIcon} />
                        <span>E-mail</span>
                      </IconTextContainer>
                      <IconButton>
                        <BlackRightArrowImg src={BlackRightArrow} />
                      </IconButton>
                    </InfoContainer>
                  </LoginByContainer>
                )}
              </>
            )}

            {showComplianceRoot && (
              <>
                {/* <VerticalSep size={30}></VerticalSep> */}
                <BackButtonContainer onClick={handleBackToLoginTypeSelection}>
                  <BackArrowImg src={IBackArrow} />
                  <BackText>{`Back to Login`}</BackText>
                </BackButtonContainer>
                <FormFieldSection>
                  <FormLabel>
                    {"Select country in which you want to access"}
                  </FormLabel>
                  <ComplianceRoot handleContrySlection={handleContrySlection} supportedCountriesData={supportedCountries} />

                  {/* <VerticalSep size={10}></VerticalSep> */}
                </FormFieldSection>
              </>
            )}
          </LoginFormRight>
        </LoginForm>
      </LoginContainer>
      <Snackbar
        open={statusReducer.showStatus}
        autoHideDuration={statusReducer.autoHideIn}
        onClose={() => {
          if (statusReducer.autoHide) {
            dispatch(unsetShowStatus());
          }
        }}
      >
        <Alert
          onClose={() => {
            dispatch(unsetShowStatus());
          }}
          severity={statusReducer.severity}
          sx={{ width: "100%" }}
        >
          {statusReducer.message}
        </Alert>
      </Snackbar>
    </>
  );
};

export default Login;
