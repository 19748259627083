import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { COLOR_SECONDARY, API_ENDPOINT, DEVICE, COOKIE_EXPIRY_DAYS, FONT_FAMILY_GENERAL } from "../utilities/constants";
import labIcon from "../assets/lab-logo@2x.png";
import { useCookies } from "react-cookie";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { setShowStatus, unsetShowStatus } from "../store/slices/statusSlice";
import { NavLink, useNavigate } from "react-router-dom";
import patientIcon from '../assets/patient-icon.svg';
import scansIcon from '../assets/scans-icon-menu.svg';
import doctorIcon from '../assets/doctor-icon-menu.svg';
import nodeIcon from '../assets/node-icon.svg';
import updatesIcon from '../assets/updates-icon.svg';
import helpIcon from '../assets/help-icon.svg';
import reportsIcon from '../assets/reports-icon.svg'
import rightBlueArrow from '../assets/arrow-right-blue.svg';
  
const Container = styled.div`
    background-color: ${COLOR_SECONDARY};
    border-radius: 12px;
    flex-grow: 1;
    padding: 56px 40px;
    min-height: 600px;
    box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
    @media ${DEVICE.mobileL} {
        padding: 20px 15px;
    }
    display: flex;
    flex-direction: column;
`;

const Header = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 65px;
`;

const HeaderLeft = styled.div`
    padding-right: 40px;
`;

const LabLogo = styled.img`
    height: 124px;
    width: 124px;
`;

const HeaderRight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 12px;
    padding-left: 40px;
    border-left: 1px solid #D7EFF7;
`;

const Welcome = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
`;

const WelcomeText = styled.div`
    font-size: 48px;
    color: #000000;
    font-family: ${FONT_FAMILY_GENERAL};
    font-weight: 700;
`;

const LabName = styled.div`
    font-size: 48px;
    color: #0099CC;
    padding-left: 5px;
    font-family: ${FONT_FAMILY_GENERAL};
    font-weight: 700;
`;

const PatientInfo = styled.div`
    font-size: 28px;
    color: #000000;
    font-family: ${FONT_FAMILY_GENERAL};
    font-weight: 500;
`;

const MenuSection = styled.div`
`;

const MenuContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 24px;
    margin-bottom: 24px;
`;

const MenuBox = styled.div`
    flex: 1 1 calc(25% - 24px);
    height: 56px;
    border-radius: 8px;
    background-color: #EBF7FB;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 52px 24px;
    gap: 24px;
    cursor: pointer;
    position: relative;
    max-width: 28%;

    &:hover .arrow-icon {
        opacity: 1;
    }
`;

const MenuIconContainer = styled.div`
`;

const MenuIcon = styled.img`
    width: 56px;
    height: 56px;
`;

const MenuText = styled.div`
    font-size: 28px;
    color: #000000;
`;

const ArrowIcon = styled.img`
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
    position: absolute;
    right: 24px;
`;

const MainHome = () => {
    const [cookies, setCookie, removeCookie] = useCookies();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [isLoading, setIsLoading] = useState(false);
    const [userDetails, setUserDetails] = useState(null);

    useEffect(() => {
        if (!cookies["access_token"]) {
          navigate("/login");
        } else {
          fetchUserDetails();
        }
      }, []);

    const fetchUserDetails = () => {
        setIsLoading(true);
    
        const token = cookies["access_token"];
        const url = API_ENDPOINT.PRACTITIONER_ROLE_INFO;
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        };
        axios
          .get(url, config)
          .then((response) => {
            if (response && response.data && response.data.data) {
              setUserDetails(response.data.data);
              setIsLoading(false);
              const maxAge = 60 * 60 * 24 * COOKIE_EXPIRY_DAYS;
              setCookie("lab_id", response?.data?.data?.id, {
                path: "/",
                maxAge: maxAge,
              });
            } else {
              dispatch(
                setShowStatus({
                  message: "Something went wrong",
                  severity: "error",
                  autoHide: "no",
                })
              );
            }
          })
          .catch((error) => {
            console.log(error);
            dispatch(
              setShowStatus({
                message: JSON.stringify(error,null,2) || "Something went wrong!",
                severity: "error",
                autoHide: "no",
              })
            );
          })
          .finally(() => {
            setIsLoading(false);
          });
    };

    return (
        <>
            <Container>
                <Header>
                    <HeaderLeft>
                        <LabLogo src={labIcon}></LabLogo>
                    </HeaderLeft>
                    <HeaderRight>
                        <Welcome>
                            <WelcomeText>Welcome,</WelcomeText>
                            <LabName>{userDetails && userDetails.lab ? userDetails.lab : "Lab"}</LabName>
                        </Welcome>
                        <PatientInfo>{userDetails?.contacts[0].system === 'phone' ? userDetails?.contacts[0].value : ''}</PatientInfo>
                    </HeaderRight>
                </Header>
                <MenuSection>
                    <MenuContainer>
                        <MenuBox onClick={() => {
                            navigate('/patients')
                        }}>                            
                            <MenuIconContainer>
                                <MenuIcon src={patientIcon} />
                            </MenuIconContainer>
                            <MenuText>Patients</MenuText>
                            <ArrowIcon src={rightBlueArrow} className="arrow-icon" />
                        </MenuBox>
                        <MenuBox onClick={() => {
                            navigate('/scans')
                        }}>
                            <MenuIconContainer>
                                <MenuIcon src={scansIcon} />
                            </MenuIconContainer>
                            <MenuText>Scans</MenuText>
                            <ArrowIcon src={rightBlueArrow} className="arrow-icon" />
                        </MenuBox>
                        <MenuBox onClick={() => {
                            navigate('/pacs-and-logs')
                        }}>
                            <MenuIconContainer>
                                <MenuIcon src={nodeIcon} />
                            </MenuIconContainer>
                            <MenuText>Configurations</MenuText>
                            <ArrowIcon src={rightBlueArrow} className="arrow-icon" />
                        </MenuBox>
                    </MenuContainer>
                    <MenuContainer >
                        <MenuBox onClick={() => {
                            navigate('/doctors')
                        }}>
                            <MenuIconContainer>
                                <MenuIcon src={doctorIcon} />
                            </MenuIconContainer>
                            <MenuText>Doctors</MenuText>
                            <ArrowIcon src={rightBlueArrow} className="arrow-icon" />
                        </MenuBox>
                        {/* <MenuBox onClick={() => {
                            navigate('/reports')
                        }}>
                            <MenuIconContainer>
                                <MenuIcon src={reportsIcon} />
                            </MenuIconContainer>
                            <MenuText>Reports</MenuText>
                            <ArrowIcon src={rightBlueArrow} className="arrow-icon" />
                        </MenuBox> */}
                        <MenuBox onClick={() => {
                            navigate('/templates')
                        }}>
                            <MenuIconContainer>
                                <MenuIcon src={reportsIcon} />
                            </MenuIconContainer>
                            <MenuText>Templates</MenuText>
                            <ArrowIcon src={rightBlueArrow} className="arrow-icon" />
                        </MenuBox>
                        <MenuBox onClick={() => {
                            navigate('/help')
                        }}>
                            <MenuIconContainer>
                                <MenuIcon src={helpIcon} />
                            </MenuIconContainer>
                            <MenuText>Help</MenuText>
                            <ArrowIcon src={rightBlueArrow} className="arrow-icon" />
                        </MenuBox>

                       
                    </MenuContainer>
                </MenuSection>
            </Container>
        </>
    )
}

export default MainHome;