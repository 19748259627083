import axios from "axios";
import { Paper, Typography, Box, IconButton, Tooltip } from "@mui/material";
import GetAppIcon from "@mui/icons-material/GetApp";
import "./MetaInfo.css";
import PatientInfo from "./PatientInfo";
import LabInfo from "./LabInfo";
import styled from "styled-components";
import ExtraStudyInfo from "./ExtraStudyInfo";
import { useCookies } from "react-cookie";
import { useRef, useState } from "react";
import { API_URL } from "../../../utilities/constants";
import PatientReportPdf from "../../../pages/PatientReportPdf";
import ScanDeleteSure from "../Dialog/ScanDeleteSure";
interface MetaInfoProps {
  header?: string;
  recordInfo: any;
  scans: {
    modality_code: string;
  }[]
}

const HrLine = styled.div`
  border: 1px solid #c5dde0;
  margin: 1rem 0;
`;

const Color = {
  color: "#FFF",
};

const MetaInfo: React.FC<MetaInfoProps> = ({
  header = "File Information",
  recordInfo,
  scans,
}) => {
  const [isShowDownload, setShowDownload] = useState(false);
  const [isDownLoading, setDownlading] = useState(false);
  const [downloadingData, setDownloadingData] = useState({});
  const [isPDf, setIsPdf] = useState(false);
  const [scansAuthToken, setScansAuthToken] = useState("");
  const [downloadingImageData, setDownloadingImageData] = useState({});


  const studyDownloadRef: any = useRef<HTMLDivElement>();
  const [cookies] = useCookies();


  const onDownload = async (downloadStudyId: string) => {
    setShowDownload(true);
    fetchDataForDownload(downloadStudyId);
  };


  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function displayProtectedImage(imageUrl: any, token: any) {
    const responses = await fetch(imageUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Image/jpeg",
      },
    });
    let dataaaa = await responses.blob();
    return dataaaa;
  }


  const fetchDataForDownload = (downloadStudyId: string) => {
    setDownlading(true);
    const token = cookies["access_token"];
    const Url = `${API_URL}/list_data/study/pamphlet?study_fhir_id=${downloadStudyId}`;
    const scansImagesUrl = `${API_URL}/list_data/frame_urls?study_fhir_id=${downloadStudyId}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(Url, config)
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          setDownloadingData(response?.data?.data);

          axios.get(scansImagesUrl, config).then(async (responseData) => {
            if (
              responseData &&
              responseData.data &&
              responseData.data.status === "success"
            ) {
              setScansAuthToken(responseData.data.data.auth_token);
              const frames = await Object.keys(
                responseData.data.data.frame_urls
              )
                ?.slice(0, 2)
                .map((frame: any) => responseData.data.data.frame_urls[frame]);

             const fetchFrameImages = async ()=> {
                   const  framesPromise = frames.map(async(frameArray)=>{
                    const fetchProtectedImage = frameArray.map(
                      async (itemUrl: any) => {

                        let url = await displayProtectedImage(
                          `${itemUrl}`,
                          responseData.data.data.auth_token
                        );
                        let base64 = await blobToBase64(url);
                        return base64
                      }
                   )
                   const fetchProtectedImageArr = await Promise.all(
                    fetchProtectedImage
                  );
                  return fetchProtectedImageArr;
                  })
                  return await Promise.all(framesPromise)
              }

              fetchFrameImages().then((fetchProtectedImageArr:any)=>{
                setDownloadingImageData(fetchProtectedImageArr);
                  setIsPdf(true);
                  setTimeout(() => {
                    studyDownloadRef.current.onDownload();
                    setTimeout(() => {
                    setShowDownload(false);
                    }, 2000);
                  }, 3000);

              })
            } else {
              setIsPdf(true);
              setTimeout(() => {
                studyDownloadRef.current.onDownload();
                setShowDownload(false);
              }, 3000);
            }
          });
        }  else {
          setShowDownload(false);
          setDownlading(false);
          setDownloadingData({});
          setIsPdf(false);

          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        console.log(error);
        setShowDownload(false);
        setIsPdf(false);
        setDownlading(false);
        setDownloadingData({});
      });
  };

  return (
    <Paper
      elevation={3}
      sx={{
        background: "#D7EFF7",
        borderRadius: "0 1rem 1rem 0",
        paddingBottom: 2,
        marginTop: 2,
        flexGrow: 1,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
        sx={{ borderTopRightRadius: "1rem" }}
        className="meta-header"
      >
        <Typography variant="h6" sx={Color}>
          {header}
        </Typography>
        <Box>
          <Tooltip title="DICOM Info Pdf" arrow>
            <IconButton
              sx={Color}
              onClick={() => onDownload(recordInfo?.study_info?.fhir_id)}
            >
              <GetAppIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Box>
      <Box m={2} className="record-wrap">
        <PatientInfo recordInfo={recordInfo?.study_info} />
        <HrLine />
        <LabInfo recordInfo={recordInfo} />
        <HrLine />
        <ExtraStudyInfo recordInfo={recordInfo?.study_info} scans={scans}/>
      </Box>
      {isShowDownload ? (
        <ScanDeleteSure
          open={isShowDownload}
          isLoaderPopup={true}
          isLoading={isDownLoading}
          testId={"test"}
          title={""}
          discription={""}
          closeHandler={() => {
            setShowDownload(false);
          }}
        />
      ) : (
        ""
      )}

      {isPDf && (
        <div style={{width:'200px'}}>
          <PatientReportPdf
            ref={studyDownloadRef}
            data={downloadingData}
            downloadingImageData={downloadingImageData}
            setPdf={setIsPdf}
            scansAuthToken={scansAuthToken}
          />
        </div>
      )}
    </Paper>
  );
};

export default MetaInfo;
