import React from 'react'
import styled from "styled-components";
import moment from "moment";
import CloseIcons from "../../../assets/closeIcon.svg";
import UserImage from "../../../assets/userImage.svg";


const CustomShareCard = ({ isGroup, data, onRemove }) => {
  console.log("card data", data);
  const SelectedCard = styled.div`
    width: 100%;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    border: 2px solid #E4E4E7;
    border-radius: 8px;
    margin: 4px 0px;
;
  `;
  const SelectedItem = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  padding: 13px 9px;
  cursor: pointer;
  border: none;
  position: relative;
  font-size: 13px;
 
`;
  const ListItemPersonLogo = styled.div`
  font-size: 13px;
`
  const ListItemPersonDetail = styled.div`
  align-items: center;
  font-size: 13px;
  margin-left: 8px;


`;
  const ListItemValue = styled.div`
  font-size: 14px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 238px;
    padding-bottom:4px;

`;

  const Name = styled.div`
  font-size: 16px;
  color: #0099cc;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
  padding-bottom:4px;
`;
  const CloseIcon = styled.div`
  position: absolute;
  right: 35px;
  top: 20px;
`;
  return (
    <>
      <SelectedCard>
        <SelectedItem>
        {!isGroup && <CloseIcon onClick={() => {
            console.log("data.doctor_id",data.doctor_id);
            onRemove(data.doctor_id)
          }}>
          <img src={CloseIcons}  />
        </CloseIcon>}
          <ListItemPersonLogo>
            {isGroup ? <svg width="40" height="40" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M24 48C37.2548 48 48 37.2548 48 24C48 10.7452 37.2548 0 24 0C10.7452 0 0 10.7452 0 24C0 37.2548 10.7452 48 24 48Z" fill="#DDEBED" />
              <path d="M10.1457 16.2083C10.1457 19.43 12.7573 22.0417 15.979 22.0417C19.2007 22.0417 21.8123 19.43 21.8123 16.2083C21.8123 12.9867 19.2007 10.375 15.979 10.375C12.7573 10.375 10.1457 12.9867 10.1457 16.2083ZM9.4165 36.625V30.0625C9.4165 26.4381 12.3546 23.5 15.979 23.5C19.6034 23.5 22.5415 26.4381 22.5415 30.0625V36.625H9.4165ZM32.0207 22.0417C28.7991 22.0417 26.1873 19.43 26.1873 16.2083C26.1873 12.9867 28.7991 10.375 32.0207 10.375C35.2423 10.375 37.854 12.9867 37.854 16.2083C37.854 19.43 35.2423 22.0417 32.0207 22.0417ZM25.4582 36.625V30.0625C25.4582 26.4381 28.3963 23.5 32.0207 23.5C35.6451 23.5 38.5832 26.4381 38.5832 30.0625V36.625H25.4582Z" fill="#F5FBFC" />
            </svg> : <img src={UserImage} width="40" height="40" />}

          </ListItemPersonLogo>
          <ListItemPersonDetail>
            <Name>{isGroup ? data.label : data.name}</Name>
            <ListItemValue>{isGroup ? "" : data.phone}</ListItemValue>
            <ListItemValue>{isGroup ? "" : data.email}</ListItemValue>
            <span
              style={{
                fontSize: "16px",
                fontWeight: '400px',
                marginTop: "2px",
                display: 'inline-block',
                textTransform: "capitalize",
                color: '#808080'
              }}
            >
              {isGroup ? `+${data.numberOfDoctors}  more` : ''}         </span>
          </ListItemPersonDetail>
        </SelectedItem>
      </SelectedCard>
    </>
  )
}

export default CustomShareCard

