import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  IconButton,
  Radio,
  RadioGroup,
  Typography,
  colors,
} from "@mui/material";
import closeIcon from "../../../assets/close-icon.png";
import SimpleLoader from "../Loaders/SimpleLoader";
import styled from "styled-components";
import { VerticalSep } from "../../styled/Gaps";
import InfoIcon from "@mui/icons-material/Info";
import { useState } from "react";

const TestForm = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextFieldLabel = styled.div`
  color: #808080;
  font-size: 16px;
  margin-bottom: 10px;
`;
export interface EditTestProps {
  open: boolean;
  testId: string | null;
  title?: string | null | undefined;
  discription?: string | null;
  closeHandler: () => void;
  onConfirm: (status:string) => void;
  isLoding:boolean;
  currentStatus?: string;
}

const ChangeStatusDialog = ({
  open,
  closeHandler,
  title,
  discription,
  onConfirm,
  isLoding,
  currentStatus='RESTRICTED',
}: EditTestProps) => {
const [status,setStatus]=useState(currentStatus)

  return (
    <Dialog
      fullWidth
      open={open}
      onClose={closeHandler}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      PaperProps={{ sx: { borderRadius: "20px",padding:'30px' } }}
      
      >
      <DialogTitle
        style={{
          justifyContent: "flex-start",
          color: "#0099CC",
          margin: "20px 30px 0",
          fontWeight: "600",
        }}
      >
        {title}
        <img
          onClick={closeHandler}
          height={50}
          width={50}
          style={{
            cursor: "pointer",
            position: "absolute",
            right: " 0",
            top: "2px",
          }}
          src={closeIcon}
        />
      </DialogTitle>
      <DialogContent
        sx={{
          margin: "0px 30px 0",
        }}
      >
        <Box>
          <Typography>{discription}</Typography>
      <VerticalSep size={10} />

          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            value={status}
            name="radio-buttons-group"
            onChange={(e)=>setStatus(e.target.value)
            }
          >
            <FormControlLabel
              value="AVAILABLE"
      
      control={<Radio color="info" />}
              label="Available (visible to the patient, doctor, and others)"
            />
            <FormControlLabel value="RESTRICTED" control={<Radio  color="info" />} label="Restricted (visible to verified doctors only)" />
          </RadioGroup>
        </Box>
      </DialogContent>
      <VerticalSep size={10} />
      <DialogActions
        style={{
          justifyContent: "flex-start",
          padding: "0px 20px 20px 20px",
          margin: "20px 30px",
        }}
      >
        <>
          <Button
            onClick={()=>onConfirm(status)}
            sx={{
              color: "#FFF",
              minWidth: "150px",
              borderRadius: "8px",
              height: "44px",
            }}
            disabled={isLoding}
            variant="contained"
          >
            {isLoding ?(
                    // <SimpleLoader height="100%" size={20} />
                    <CircularProgress size={20} color="inherit" />
                  ):"Confirm"}
          </Button>
          <Button
            onClick={closeHandler}
            sx={{ minWidth: "150px", borderRadius: "8px", height: "44px" }}
            variant="outlined"
          >
            Cancel
          </Button>
        </>
      </DialogActions>
    </Dialog>
  );
};

export default ChangeStatusDialog;
