import axios from "axios";
import moment from "moment";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import CustomTable from "../components/common/CustomTable/CustomTable";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import SearchBox from "../components/common/SearchBox/SearchBox";
import UserDropdown from "../components/common/UserDropdown/UserDropdown";
import NoData from "../components/common/UserMessages/NoData";
import { HorizontalSep, VerticalSep } from "../components/styled/Gaps";
import BackArrow from "../../src/assets/blue-back-arrow.svg"
import { useSelector, useDispatch } from "react-redux";
import { setShowStatus } from "../store/slices/statusSlice";
import {
    API_URL,
    COLOR_GREY,
    COLOR_PRIMARY,
    COLOR_PRIMARY_LIGHT,
    COLOR_SECONDARY,
    DEVICE,
    COOKIE_EXPIRY_DAYS,
    API_ENDPOINT,
} from "../utilities/constants";
import { Box, Checkbox, FormControl, MenuItem, Select, FormControlLabel, SelectChangeEvent, Typography, CircularProgress } from "@mui/material";
import { setAutosend } from '../store/slices/autosendSlice';
import InfoDialog from "../components/common/Dialog/InfoDialog";

interface Config {
    id: number;
    config_name: string;
    config_display_name: string;
    config_value: string;
    config_type: string;
    sub_configs?: Array<Config>
}

interface LabUsers {
    id: string;
    phone_number: string;
    email: string;
}

const Container = styled.div`
    background-color: ${COLOR_SECONDARY};
    border-radius: 12px;
    flex-grow: 1;
    padding: 30px;
    min-height: 100%;
    box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
    @media ${DEVICE.mobileL} {
        padding: 20px 15px;
    }
`;

const Header = styled.div`
  font-size: 24px;
  color: #0099CC;
  font-weight: 600;
`;

const BackArrowImg = styled.img`
    margin-right: 5px;
    cursor: pointer;
`;

const SectionHeader = styled.div`
    color: ${COLOR_PRIMARY};
    font-size: 28px;
`;

const PacsLogsContainer = styled.div`
    display: flex;
    flex-direction: column;
`

const ServerContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 48px;
`

const LogsContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 30px 48px;
`

const ServerInfoHeader = styled.div`
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #0099CC;
    color: #FFFFFF;
    font-size: 20px;
    padding: 12px 40px;
`

const ServerInfoContent = styled.div`
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #F5FBFC;
    padding: 18px 40px;
    margin-bottom: 1rem;
`

const LogsHeader = styled.div`
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    background-color: #0099CC;
    color: #FFFFFF;
    font-size: 20px;
    padding: 12px 40px;
`

const LogsContent = styled.div`
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
    background-color: #F5FBFC;
    padding: 18px 48px;
`

const IPAddress = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const AppEntity = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const Port = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const LogsList = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #D7EFF7;
`

const LogsDate = styled.div`
    font-size: 16px;
    color: #404040;
`

const LogsName = styled.div`
    font-size: 16px;
    color: #404040;
`

const LogsStatus = styled.div`
    font-size: 16px;
    color: #404040;
`

const IPText = styled.div`
    font-size: 16px;
    color: #404040;
    min-width: 152px;
`

const CopyButton = styled.div`
    font-size: 16px;
    color: #404040;
    background-color: #EBF7FB;
    border-radius: 5px;
    border: 1px solid #D7EFF7;
    padding: 6px 12px;
    cursor: pointer;
`

const LogsSize = styled.div`
    font-size: 14px;
    color: #0099CC;
    background-color: #EBF7FB;
    border-radius: 5px;
    border: 1px solid #D7EFF7;
    padding: 6px 12px;
`

const IPNum = styled.div`
    font-size: 14px;
    color: #0099CC;
    background-color: #EBF7FB;
    border-radius: 5px;
    border: 1px solid #D7EFF7;
    padding: 6px 12px;
`
const AddTempWrapper = styled.div`
  // margin-left: 10px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const AddButton = styled.button`
  background-color: #0099CC;
  border-radius: 8px;
  color: #ffffff;
  font-size: 16px;
  min-width: 120px;
  max-width: 400px;
  height: 40px;
  border: none;
  cursor: pointer;
  padding: 0 10px;
  margin-top: 2%;

  &:disabled {
    background-color: #cccccc;
    color: #666666;
    cursor: not-allowed;
  }
`;

const PacsLogs = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [cookies, setCookie, removeCookie] = useCookies();
    const [pacsData, setPacsData] = useState<any>({});
    const [adapterData, setAdapterData] = useState<any>({});
    const [checked, setChecked] = useState<boolean>(false);
    const [configsList, setConfigsList] = useState<Config[]>([]);
    const [labUsersList, setLabUsersList] = useState<LabUsers[]>([]);
    const [selectedLabUser, setSelectedLabUser] = useState<string>("");
    const [isLoadingDiacom, setIsLoadingDiacom] = useState<boolean>(false);
    const [isDiacomLoadSuccess, setIsDiacomLoadSuccess] = useState(false);
    const [autosendAsRestricted, setAutosendAsRestricted] = useState<boolean>(false);




    useEffect(() => {
        fetchPacsInfo();
        getAdapterInfo()
        const cookieValue = cookies["direct_link"];
        if (cookieValue !== undefined) {
            setChecked(JSON.parse(cookieValue));
        }
    }, []);

    useEffect(() => {
        getAllConfigs();
        getConfigLabUsers();
    }, []);

    useEffect(() => {
        // Auto-select the lab user if config_value is present
        const labUserConfig = configsList.find(config => config.config_name == "autosend")
        const subConfig = labUserConfig ? labUserConfig?.sub_configs?.find(subConfig => subConfig.config_type === "list") : null;
        if (subConfig && subConfig.config_value) {
            try {
                const parsedValue = JSON.parse(subConfig.config_value);
                setSelectedLabUser(parsedValue.key);
            } catch (e) {
                setSelectedLabUser(""); // Set to empty string if parsing fails
            }
        } else {
            setSelectedLabUser(""); // Ensure dropdown is blank if no config_value is present
        }
    }, [configsList]);

    const fetchPacsInfo = () => {
        setIsLoading(true)
        const token = cookies["access_token"];
        const url = `${API_URL}/pacs_info`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.status === "success") {
                    setPacsData(response?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };


    const getAdapterInfo = () => {
        setIsLoading(true)
        const token = cookies["access_token"];
        const url = `${API_URL}/adapter`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.status === "success") {
                    console.log("response",response);

                    setAdapterData(response?.data?.result);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const handleCopyToClipboard = (text : string) => {
        navigator.clipboard.writeText(text)
            .then(() => {
                dispatch(
                    setShowStatus({
                        message: "Copied to clipboard",
                        severity: "success",
                        autoHide: "yes",
                    })
                );
            })
            .catch((err) => {
                console.error('Failed to copy text: ', err);
            });
    };

    const getAllConfigs = () => {
        setIsLoading(true);
        const token = cookies["access_token"];
        const url = `${API_ENDPOINT.CONFIGURATION_LIST}`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.status === "success") {
                    setConfigsList(response?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const getConfigLabUsers = () => {
        setIsLoading(true);
        const token = cookies["access_token"];
        const url = `${API_ENDPOINT.CONFIG_LAB_USERS}`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        axios
            .get(url, config)
            .then((response) => {
                if (response?.data?.status === "success") {
                    setLabUsersList(response?.data?.data);
                }
            })
            .catch((error) => {
                console.log(error);
            })
            .finally(() => {
                setIsLoading(false);
            });
    };

    const editConfiguration = (id: number, configValue: string) => {
        const token = cookies["access_token"];
        const url = `${API_ENDPOINT.EDIT_CONFIGURATION}/${id}`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        const data = {
            config_value: configValue,
        };
        axios
            .put(url, data, config)
            .then((response) => {
                if (response?.data?.status === "success") {
                    dispatch(
                        setShowStatus({
                            message: "Configuration updated successfully",
                            severity: "success",
                            autoHide: "yes",
                        })
                    );
                }
            })
            .catch((error) => {
                console.error('Failed to update configuration:', error);
            });
    };

    const handleCheckboxChange = (config: Config, checked: boolean) => {

        if (config.config_name == "autosend_as_restricted") {
            const updatedValue = checked.toString();
            setConfigsList((prevConfigs) =>
                prevConfigs.map((prevConfig) => {
                    if (config && config.config_name === 'autosend_as_restricted') {

                        return {
                            ...prevConfig,
                            sub_configs: prevConfig.sub_configs?.map((subConfig) => {
                                if (subConfig.config_name === config.config_name) {
                                    return {
                                        ...subConfig,
                                        config_value: updatedValue,
                                    };
                                }
                                return subConfig;
                            }),
                        };
                    }
                    return prevConfig;
                })
            );
            editConfiguration(config.id, updatedValue);

        } else {
            const updatedValue = checked.toString();
            setConfigsList(prevConfigs =>
                prevConfigs.map(prevConfig =>
                    prevConfig.id === config.id
                        ? { ...prevConfig, config_value: updatedValue }
                        : prevConfig
                )
            );
            editConfiguration(config.id, updatedValue);
        }

        if (config.config_name === "autosend") {
            dispatch(setAutosend(checked));
        }
    };

    const handleLabUserChange = (event: SelectChangeEvent<string>, config: Config) => {
        const selectedUser = labUsersList.find(user => user.id === event.target.value);
        if (selectedUser) {
            const updatedValue = JSON.stringify({
                key: selectedUser.id,
                value: selectedUser.phone_number,
            });
            setSelectedLabUser(selectedUser.id);
            editConfiguration(config.id, updatedValue);
        }
    };
    const onLoadDiacomTestData = async () => {
        setIsLoadingDiacom(true);
        const token = cookies["access_token"];
        const url = `${API_URL}/report/upload/test_data`;
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
            },
        };
        try {
            const response = await axios.post(url, {}, config);
            if (response?.data?.status === "success") {
                if (response?.data) {
                    console.log("response", response);

                    setIsLoadingDiacom(false);
                    setIsDiacomLoadSuccess(true);
                    setTimeout(() => {
                        setIsDiacomLoadSuccess(false);
                    }, 3000);
                }
            }
        } catch (error) {
            console.error(error);
            setIsDiacomLoadSuccess(false);
            setIsLoadingDiacom(false);
        }
    };

    return (
        <>
            <Box display={"flex"} justifyContent={"space-between"} alignItems={'center'}>
                <Header>Configurations Details</Header>
                <AddTempWrapper>
                    <div style={{ display: "flex", flexDirection: "column", marginLeft: "10px" }}>
                        <AddButton onClick={onLoadDiacomTestData}>
                            {isLoadingDiacom ? (
                                // <SimpleLoader height="100%" size={20} />
                                <CircularProgress size={20} color="inherit" />
                            ) : (
                                "Load Test Data"
                            )}
                        </AddButton>
                    </div>
                </AddTempWrapper>
            </Box>
            <VerticalSep size={28} />
            <Container>
                <PacsLogsContainer>
                    {!isLoading ? (
                        <ServerContainer>
                            <ServerInfoHeader>
                                Server Information
                            </ServerInfoHeader>
                            <ServerInfoContent>
                                <IPAddress>
                                    <IPText>IP Address</IPText>
                                    <IPNum>{pacsData?.adapter_ip}</IPNum>
                                    <CopyButton onClick={() => handleCopyToClipboard(pacsData?.adapter_ip)}>Copy</CopyButton>
                                </IPAddress>
                                <AppEntity>
                                    <IPText>Application Entity No.</IPText>
                                    <IPNum>{pacsData?.adapter_ae_title}</IPNum>
                                    <CopyButton onClick={() => handleCopyToClipboard(pacsData?.adapter_ae_title)}>Copy</CopyButton>
                                </AppEntity>
                                <Port>
                                    <IPText>Port</IPText>
                                    <IPNum>{pacsData?.adapter_port}</IPNum>
                                    <CopyButton onClick={() => handleCopyToClipboard(pacsData?.adapter_port)}>Copy</CopyButton>
                                </Port>
                            </ServerInfoContent>
                            {adapterData && adapterData?.configuration_value ?
                                <>
                                    <ServerInfoHeader>
                                        Adapter Details
                                    </ServerInfoHeader>
                                    <ServerInfoContent>
                                        <IPAddress>
                                            <IPText>{adapterData?.configuration_value}</IPText>
                                            <IPNum>{adapterData?.configuration_parameter}</IPNum>
                                            <CopyButton onClick={() => handleCopyToClipboard(adapterData?.configuration_parameter)}>Copy</CopyButton>
                                        </IPAddress>

                                    </ServerInfoContent>
                                </> :
                                <>
                                    <ServerInfoHeader>
                                        Adapter Details
                                    </ServerInfoHeader>
                                    <ServerInfoContent>
                                        <IPAddress>
                                            <IPText>{"Details Not Configured"}</IPText>
                                        </IPAddress>

                                    </ServerInfoContent>
                                </>

                            }
                            <>
                                <ServerInfoHeader>
                                    Settings
                                </ServerInfoHeader>
                                <ServerInfoContent>
                                    {configsList.map((config: Config) =>
                                        config.config_type === "boolean" ? (
                                            <Box
                                                key={config.id}
                                                pt={1}
                                                pb={1}
                                            >
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            checked={config.config_value.toLowerCase() === "true"}
                                                            onChange={(e) =>
                                                                handleCheckboxChange(config, e.target.checked)
                                                            }
                                                        />
                                                    }
                                                    label={config.config_display_name}
                                                />
                                                {config.config_value.toLowerCase() === "true" ?
                                                    <Box>
                                                        {config?.sub_configs && config?.sub_configs.map((subConfig: Config) => {

                                                            return subConfig.config_type === "boolean" ? (
                                                                <Box
                                                                    key={subConfig.id}
                                                                    display="flex"
                                                                    alignItems="center"
                                                                    pt={1}
                                                                    pb={1}
                                                                    ml={4}
                                                                >
                                                                    <FormControlLabel
                                                                        control={
                                                                            <Checkbox
                                                                                checked={subConfig.config_value.toLowerCase() === "true"}
                                                                                onChange={(e) =>
                                                                                    handleCheckboxChange(subConfig, e.target.checked)
                                                                                }
                                                                            />
                                                                        }
                                                                        label={subConfig.config_display_name}
                                                                    />
                                                                </Box>
                                                            ) : subConfig.config_type === "list" ? (

                                                                <Box key={subConfig.id} display="flex" alignItems="center" margin="normal" ml={4} >
                                                                    <Typography variant="body1" sx={{ marginRight: '16px' }}>
                                                                        {subConfig.config_display_name}:
                                                                    </Typography>
                                                                    <FormControl sx={{ width: '200px' }}>
                                                                        <Select
                                                                            id="lab-user-select"
                                                                            value={selectedLabUser}
                                                                            onChange={(event) => handleLabUserChange(event, subConfig)}
                                                                            displayEmpty
                                                                        >
                                                                            <MenuItem disabled value="">
                                                                                Select Lab User
                                                                            </MenuItem>
                                                                            {labUsersList.map((user) => (
                                                                                <MenuItem key={user.id} value={user.id}>
                                                                                    {user.phone_number}
                                                                                </MenuItem>
                                                                            ))}
                                                                        </Select>
                                                                    </FormControl>
                                                                </Box>
                                                            ) : null
                                                        }

                                                        )}
                                                    </Box> : null
                                                }
                                            </Box>
                                        ) : null
                                    )}

                                    {/* {configsList.map((config: Config) => {
                                        if (config.config_type === "list") {
                                            return (
                                                <Box key={config.id} display="flex" alignItems="center" margin="normal">
                                                    <Typography variant="body1" sx={{ marginRight: '16px' }}>
                                                        {config.config_display_name}:
                                                    </Typography>
                                                    <FormControl sx={{ width: '200px' }}>
                                                        <Select
                                                            id="lab-user-select"
                                                            value={selectedLabUser}
                                                            onChange={(event) => handleLabUserChange(event, config)}
                                                            displayEmpty
                                                        >
                                                            <MenuItem disabled value="">
                                                                Select Lab User
                                                            </MenuItem>
                                                            {labUsersList.map((user) => (
                                                                <MenuItem key={user.id} value={user.id}>
                                                                    {user.phone_number}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl>
                                                </Box>
                                            );
                                        }
                                        return null;
                                    })} */}
                                </ServerInfoContent>
                            </>
                        </ServerContainer>
                    ) : <SimpleLoader />
                    }
                    {/* <LogsContainer>
                        <LogsHeader>
                            Logs
                        </LogsHeader>
                        <LogsContent>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                            <LogsList>
                                <LogsDate>2023-11-22 06:00:13.777621</LogsDate>
                                <LogsName>CR-Chest_20231121.dcm</LogsName>
                                <LogsStatus>Upload</LogsStatus>
                                <LogsSize>718574 bytes</LogsSize>
                            </LogsList>
                        </LogsContent>
                    </LogsContainer> */}
                </PacsLogsContainer>
            </Container>



            {isDiacomLoadSuccess ? (
                <InfoDialog
                    open={isDiacomLoadSuccess}
                    testId={"test"}
                    title={""}
                    discription={
                        "Your request has been accepted. Please refresh scans page after sometime to see the loaded files."
                    }
                    closeHandler={() => {
                        setIsDiacomLoadSuccess(false);
                    }}
                />
            ) : (
                ""
            )}
        </>
    )

}

export default PacsLogs;