import React from 'react'

function ShortingArrow() {
  return (
    <div>
      <svg width="8" height="10" viewBox="0 0 8 4" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M0 0L4 4L8 0H0Z" fill="black"/>
</svg>
    </div>
  )
}

export default ShortingArrow
