import { Box } from "@mui/material";
import { FC, useEffect, useState } from "react";
import styled from "styled-components";
import { removeSpecialCharacters } from "../../../utilities/helper";
import { getViewerUrl } from "../../../apis/report";
// @ts-ignore
import { ShimmerTitle } from "react-shimmer-effects";

const FileName = styled.div`
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 0.7rem;
`;

const ListItemWrapper = styled.div`
  padding: 0px;
  border-radius: 8px;
  cursor: pointer;
  transition: transform 0.2s;
  width: 100%;
`;

const ThumbImages = styled.div`
  position: relative;
  background: #ebf7fa;
  display: flex;
  justify-content: start;
  color: #fff;
  border-radius: 8px;
  overflow: hidden;
  height: 80px;
  align-items: center;
`;

const Button = styled.button`
  background-color: #7aba56;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  margin-right: 5px;
  border-radius: 1rem;
  font-size: 0.9rem;
  cursor: pointer;
`;

const ThumbImage = styled.img`
  height: 100%;
  object-fit: contain;
  margin-bottom: 10px;
  border-radius: 8px;
`;

interface FileInfoSectionProps {
  recordInfo?: any;
}
interface ViewerInterface {
  [key: string]: string;
}

const FileInfoSection: FC<FileInfoSectionProps> = ({ recordInfo }) => {
  const [viewerData, setViewerData] = useState<ViewerInterface | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  const fetchViewerUrls = async (id: string) => {
    setLoading(true);
    try {
      const payload = {
        study_id: id,
        store_id: recordInfo?.store_id,
      };
      const { data } = await getViewerUrl({ payload: payload });
      if (data?.status === "success") setViewerData(data?.data);
    } catch (error) {
      console.log("error occured while fetching viewer urls");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (recordInfo?.study_id) fetchViewerUrls(recordInfo.study_id);
  }, []);
  
  const handleScanTmtvClick = async (viewer: string) => {
    const newWindow = window.open("", "_blank");
    if (!newWindow) {
      alert(
        "Unable to open the new window. Please disable your popup blocker and try again."
      );
      return;
    }
    newWindow.location.href = viewer;
  };

  return (
    <Box display="flex" alignItems="center" m={2} mt={4} mb={4}>
      <Box display="flex" alignItems="center">
        <ListItemWrapper>
          <ThumbImages>
            {recordInfo?.thumbnail && (
              <ThumbImage src={recordInfo.thumbnail} alt="records-thumbnail" />
            )}
          </ThumbImages>
        </ListItemWrapper>
      </Box>
      <Box ml={2}>
        <FileName>
          File Name: {removeSpecialCharacters(recordInfo?.patient_name)}
        </FileName>
        {!loading ? (
          <Box display="flex">
            {viewerData &&
              Object.keys(viewerData).map((key) => (
                <Button onClick={() => handleScanTmtvClick(viewerData[key])}>
                  {key}
                </Button>
              ))}
          </Box>
        ) : (
          <ShimmerTitle line={2} gap={10} variant="primary" />
        )}
      </Box>
    </Box>
  );
};

export default FileInfoSection;
