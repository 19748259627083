import React from 'react'

function AttachIcon() {
  return (
    <div><svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 1.66536H1.33333V12.332H10.6667V4.33203H8V1.66536ZM0 0.993231C0 0.628065 0.298327 0.332031 0.665667 0.332031H8.66667L11.9998 3.66536L12 12.9937C12 13.3646 11.7034 13.6654 11.3377 13.6654H0.662267C0.296507 13.6654 0 13.3618 0 13.0042V0.993231ZM5.33333 6.33203V4.33203H6.66667V6.33203H8.66667V7.66536H6.66667V9.66536H5.33333V7.66536H3.33333V6.33203H5.33333Z" fill="white"/>
    </svg>
    </div>
  )
}

export default AttachIcon