import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface AutosendState {
  isEnabled: boolean;
}

const initialState: AutosendState = {
  isEnabled: false, // Default to false, can be changed based on the initial configuration
};

const autosendSlice = createSlice({
  name: 'autosend',
  initialState,
  reducers: {
    setAutosend(state, action: PayloadAction<boolean>) {
      state.isEnabled = action.payload;
    },
  },
});

export const { setAutosend } = autosendSlice.actions;
export default autosendSlice.reducer;
