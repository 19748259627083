import React, { ChangeEvent, useEffect, useState } from "react";
import { Box, Typography, Paper, Grid } from "@mui/material";
import MetaInfo from "../MetaInfo/MetaInfo";
import DisplaySeries from "./DisplaySeries";
import FileInfoSection from "./FileInfoSection";
import { removeSpecialCharacters } from "../../../utilities/helper";
import "./InfoSection.css";
import styled from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { getInfoDetails, getScansInfo } from "../../../apis/report";
// @ts-ignore
import { ShimmerContentBlock } from "react-shimmer-effects";
import Breadcrumb from "../Breadcrumb/Breadcrumb";

const TopHeader = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 1.6rem;
  font-color: "#000";
  margin-top: 1rem;
`;

const InfoSection = () => {
  const location = useLocation();
  const { studyFhirId } = location.state || {};
  const [recordInfo, setRecordInfo] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [scans, setScans] = useState([]);
  const navigate = useNavigate();

  const fetchStudyInfo = async () => {
    setLoading(true);
    try {
      const [response, scanInfo] = await Promise.all([getInfoDetails(studyFhirId), getScansInfo(studyFhirId)]) ;
      if (response?.data?.status === "success") {
        setRecordInfo(response.data.data);
      }
      if(scanInfo?.data?.status === "success") {
        setScans(scanInfo.data?.data);
      }
    } catch (error) {
      console.log("Error occured while fetching info details of study or scan");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchStudyInfo();
  }, []);

  const handleBackNavigation = (event: React.MouseEvent<HTMLElement>) => {
    event.preventDefault();
    navigate(-1);
  };

  const breadCrubLinks = [
    {
      label: "Home",
      href: "/",
    },
    {
      label: "Patients",
      href: "/patients",
    },
    {
      label: "Health Data",
      onClick: handleBackNavigation,
    },
    {
      label: "Scan",
    },
  ];

  return (
    <Box ml={5}>
      <Breadcrumb links={breadCrubLinks} />
      <TopHeader>Studies</TopHeader>
      {!loading && recordInfo ? (
        <Box display="flex" mt={2} alignItems="stretch">
          <Grid item display="flex">
            <Paper
              className="box-wrapper"
              sx={{
                background: "#EBF7FB",
                borderRadius: { xs: "1rem 1rem 0 0", md: "1rem 0 0 1rem" },
                paddingBottom: 2,
                display: "flex",
                flexDirection: "column",
                flexGrow: 1,
              }}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                mb={2}
                className="info-header"
                sx={{ borderTopLeftRadius: "1rem", paddingBottom: 3 }}
              >
                <Typography variant="h6" sx={{ color: "#FFF" }}>
                  {removeSpecialCharacters(recordInfo.study_info.patient_name)}
                </Typography>
              </Box>
              <Box>
                <FileInfoSection recordInfo={recordInfo.study_info} />
                <Box
                  display="flex"
                  m={2}
                  p={2}
                  flexDirection="column"
                  sx={{ background: "#FFF", borderRadius: 4 }}
                >
                  <Box className="series-text" mt={3} ml={2}>
                    Series {scans?.length ? `(${scans.length})` : ''}
                  </Box>
                  <Box display="flex">
                    <DisplaySeries recordInfo={scans} />
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item display="flex">
            <MetaInfo recordInfo={recordInfo} scans={scans} />
          </Grid>
        </Box>
      ) : (
        <ShimmerContentBlock
          title
          text
          cta
          thumbnailWidth={86}
          thumbnailHeight={86}
        />
      )}
    </Box>
  );
};

export default InfoSection;
