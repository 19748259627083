import React, { useEffect, useRef, useState } from "react";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Skeleton,
  OutlinedInput,
  ListItemText,
  Chip,
} from "@mui/material";
import Table from "@mui/material/Table";

import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";

import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AccountCircle from "@mui/icons-material/AccountCircle";
import InputAdornment from "@mui/material/InputAdornment";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import DeleteIcon from "@mui/icons-material/Delete";
import DownArrow from "../../src/assets/down-arrow-circle.svg";
import DownArrowBlack from "../../src/assets/down-arrow-black-circle.svg";

import FilterListIcon from "@mui/icons-material/FilterList";
import { visuallyHidden } from "@mui/utils";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { API_ENDPOINT } from "../utilities/constants";
import AttachIcon from "../assets/attach";
import SahreIcon from "../assets/share.svg";
import UserImage from "../assets/userImage.svg";
import CloseIcons from "../assets/closeIcon.svg";
import DeleteIcons from "../assets/delete-white.svg";
import DownloadIcon from "../assets/downloadIcon-file.svg";
import TMTVIcon from "../assets/tmtvIcon.svg";
import OHIFIcon from "../assets/ohifIcon.svg";
import ReshareIcon from "../assets/reshareIcon.svg";
import ConfirmIcon from "../assets/confirm.svg";
import {
  CircularProgress,
  Collapse,
  TextField,
} from "@mui/material";
import styled from "styled-components";
import { API_URL, DEVICE } from "../utilities/constants";
import { BorderBottom } from "@mui/icons-material";
import ActionBtn from "../components/common/ActionBtn";
import CustomSeriesTable from "./newCostomtable";
import ReportDetailsCard from "./ReportDetailsCard";
import PatientAttechedDropdown from "../components/common/SearchDropdown/PatientAttechedDropdown";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import axios from "axios";
import moment from "moment";
import ScanDeleteSure from "../components/common/Dialog/ScanDeleteSure";
import SimpleLoader from "../components/common/Loaders/SimpleLoader";
import ShareDoctorDialog from "../components/common/Dialog/shareDoctorDialog";
import ReportAttachDialog from "../components/common/Dialog/reportAttachDialog";
import { setShowStatus } from "../store/slices/statusSlice";
import { useDispatch, useSelector } from "react-redux";
import PatientReportPdf from "./PatientReportPdf";
import ShortingArrow from "../assets/shortingArrow";
import { RootState } from "../store/store";

const StyledSelect = styled(Select)`
  .MuiSelect-select,
  MuiInputBase-root {
    padding: 2px 10px 1px 10px;
    font-size: 14px;
    background-color: rgb(255, 255, 255);
    border: 1px solid #0099cc;
    border-radius: 8px;
    height: 30px !important;
  }
  fieldset {
    border: none;
  }
`;
const Container = styled.div`
  .MuiCheckbox-colorPrimary svg {
    fill: #0099cc;
  }
  border-radius: 12px;
  flex-grow: 1;
  padding:0px;
  margin-top:-35px;
  min-height: 600px;
  // box-shadow: 0px 0px 32px 0px rgba(36, 119, 179, 0.1);
  @media ${DEVICE.mobileL} {
    padding: 20px 15px;
  }
  tbody {
    tr:hover {
      background: rgba(248, 248, 248, 0.96);
    }
  }

  .MuiTablePagination-spacer {
    display: none;
  }
  .scansTable {
    thead th {
      border-bottom: 0px;
      padding-bottom: 5px;
    }
  }
  .searchHeader {
    th {
      border-bottom: 0px solid;
    }
    th {
      padding-top: 0px;
      padding-bottom: 5px;
    }
  }
`;
export const Dropdownlist = styled.button`
  padding: 10px;
  border: none;
  text-align: left;
  cursor: pointer;
  border-bottom: 1px solid #d7f0f7;
  min-width: 100%;
  background: transparent;
  display: flex;
  gap: 5px;
  align-items: center;
  img {
    border: 1px solid #808080;
    padding: 3px;
    border-radius: 2px;
    width: 20px;
  }
`;
const ViewerButtonMenu = styled.button`
  background-color: #fff;
  border: 1px solid #808080;
  padding: 3px 15px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  gap: 4px;
  align-items: center;
  min-width: 100px;
  position: relative;
  transition: 0.3s;
  img {
    mix-blend-mode: exclusion;
    width: 9px;
  }
  &:hover {
    img {
      transform: rotate(180deg);
    }
    .custom-modal {
      display: block;
    }
  }
  .custom-modal {
    background: #fff;
    width: 100%;
    z-index: 99;
    top: 100%;
    box-shadow: 5px 3px 10px #ddd;
    left: 0;
    border-radius: 7px;
    display: none;
    position: absolute;
    overflow: hidden;
    border: 1px solid #d7f0f7;
    min-width: 130px;
  }
`;
const ArrowImageInstance = styled.img`
  width: 12px;
  height: 12px;
  padding: 8px;
  cursor: pointer;
`;

const HeaderRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  gap: 400px;
  align-items: center;
  @media ${DEVICE.mobileL} {
    flex-direction: column;
    align-items: stretch;
  }
`;
const PatientData = styled.p`
  overflow: hidden;
  margin: 0px;
  font-size: 16px;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
  font-weigth: 400px;
`;

const UserName = styled.p`
   color: #0099CC; 
    overflow: hidden;
  margin: 0px;
  font-size: 16px;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
  font-weigth: 400px;
  variant="inherit"
`;
const UserPhone = styled.p`
    overflow: hidden;
  margin: 0px;
  font-size: 16px;
  text-overflow: ellipsis;
  max-width: 200px;
  white-space: nowrap;
  font-weigth: 400px;
  variant="inherit"
`;
const SuccessBtn = styled.button`
background-color:#7ABA56;
color:#fff;
cursor:pointer;
text-align:center;
    cursor: pointer;
border:1px solid #7ABA56;
padding:7px 10px;
border-radius:5px;
min-width:130px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    &:disabled {
     background-color: #ccc;
    cursor: not-allowed;
border:none;

  }

}
`;
const PasentImage = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 50%;
`;
const RecommendedBtn = styled.div`
  background: #ffcf66;
  width: 60%;
  text-align: center;
  padding: 5px 0;
  border-radius: 0px 0px 0px 12px;
`;
const ConfirmBtn = styled.button`
  background: #0099cc;
  padding: 5px;
  color: #fff;
  width: 40%;
  text-align: center;
  border-radius: 0px 0px 12px 0px;
  cursor: pointer;
  border: none;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    border: none;
  }
`;
const CloseIcon = styled.div`
  position: absolute;
  right: 15px;
  top: 25px;
`;
const ShareCardBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  max-height: 340px;
  min-height: 180px;
  margin-top: 20px;
  overflow: auto;
`;
const PatentsCardShareText = styled.div`
  border: 2px solid #e4e4e7;
  border-bottom: 0px solid;
  border-radius: 12px 12px 0px 0px;
  min-height: 120px;
`;
const Reshare = styled.button`
  background-color: #e4e4e7;
  text-align: right;
  color: #0099cc;
  padding: 7px 15px;
  display: flex;
  align-items: center;
  gap: 5px;
  justify-content: end;
  width: 100%;
  text-align: center;
  border-radius: 0px 0px 12px 12px;
  cursor: pointer;
  border: none;

  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    border: none;
  }
`;
const PatentsCardRecommendedText = styled.div`
  border: 2px solid #ffcf66;
  border-bottom: 0px solid;
  border-radius: 12px 12px 0px 0px;
  min-height: 120px;
`;

const PatentsCardText = styled.div`
  border: 2px solid #ffcf66;
  border-radius: 12px;
`;
const PatentsCardRecommended = styled.div`
  position: relative;
  max-width: 320px;
  min-width: 320px;
`;
const TableActionBtn = styled.div`
  background-color: #cc8552;
  border-radius: 5px;
  padding: 5px;
  height: auto;
`;
const AddPasentBtn = styled.div`
  background-color: #f8f8f8;
  text-align: center;
  margin-top: 16px;
  border-radius: 5px;
  padding: 10px;
  button {
    background-color: #7aba56;
    border: 1px solid #7aba56;
    border-radius: 5px;
    padding: 10px 30px;
    color: #fff;
    font-size: 16px;
    fontwight: 500;
  }
`;
const AddNewPatient = styled.div`
  padding: 16px 16px 10px;
  border-radius: 5px;
  border: 1px solid #e4e4e7;
  fieldset {
    border-color: #92d4e9;
  }
`;
const StudyDetailsListBox = styled.div`
  .MuiTypography-root:nth-last-child(1) {
    border-bottom: 0px solid #ddd;
  }
  .MuiTypography-root {
    border-bottom: 1px solid #ddd;
    padding: 5px 0px;
  }
  font-size: 14px;
  .listtitle {
    min-width: 150px;
    display: inline-block;
  }
  .listData {
    margin-left: 20px;
  }
`;
const ClearsearchBtn = styled.button`
  color: #fff;
  font-size: 14px;
  margin-left: auto;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  border-radius: 5px;
  background: #2faed7;
  padding: 12px 15px;
  border: 1px solid #2faed7;
  white-space: nowrap;
  display: flex;
  gap: 4px;
  align-items: center;
  cursor: pointer;
`;
const TestdataBtn = styled.div`
  margin-left: auto;
  background-color: #fff;
  display: flex;
  justify-content: end;
  max-width: 500px;
  width: 100%;
`;
const AddTempWrapper = styled.div`
  // margin-left: 10px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const ReviewButtonContainer = styled.div`
  // margin-left: 10px;
  display: flex;
  width: 100%;
  gap: 4px;
  @media ${DEVICE.laptop} {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
    margin-left: 0px;
  }
`;

const ButtonBase = styled.button<{ active: boolean }>`
  border-radius: ${({ active }) =>
    active ? "8px 8px 0px 0px" : "8px 8px 8px 8px"};
  font-size: 16px;
  width: 200px;
  height: 46px;
  // border: ${({ active }) => (active ? "none" : "1px solid #7ABA56")};
  // background-color: ${({ active }) => (active ? "#7ABA56" : "#FFFFFF")};
  // color: ${({ active }) => (active ? "#FFFFFF" : "#7ABA56")};

  border: ${({ active }) => (active ? "none" : "1px solid #fff")};
  background-color: ${({ active }) => (active ? "#FFFFFF" : "#d7eff7")};
  color: ${({ active }) => (active ? "#30aed7" : "#000")};
  cursor: pointer;
`;

interface Data {
  id: number;
  calories: number;
  study_timestamp: Date;
  fat: number;
  patient_name: string;
  fhir_resource_status:string;
  patient_id: string;
  protein: number;
  series_count: number;
  price: number;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = "asc" | "desc";

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (
  a: { [key in Key]: number | string },
  b: { [key in Key]: number | string }
) => number {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: Array<any>, comparator: (a: T, b: T) => number) {
  const stabilizedThis = array.map(
    (el: any, index: any) => [el, index] as [T, number]
  );
  stabilizedThis.sort((a: any, b: any) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el: any) => el[0]);
}

interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  isSorting?: boolean;
}

const headCells: readonly HeadCell[] = [

  {
    id: "patient_name",
    numeric: false,
    disablePadding: false,
    label: "Patient Name",
    isSorting: true,
  },
  {
    id: "patient_id",
    numeric: false,
    disablePadding: false,
    label: "Patient Id",
    isSorting: false,
  },
  {
    id: "fhir_resource_status",
    numeric: false,
    disablePadding: false,
    label: "Sharing status",
    isSorting: false,
  },
  {
    id: "calories",
    numeric: true,
    disablePadding: false,
    label: "Patient attached?",
    isSorting: true,
  },
  {
    id: "fat",
    numeric: true,
    disablePadding: false,
    label: "Modality",
    isSorting: true,
  },
  {
    id: "study_timestamp",
    numeric: true,
    disablePadding: false,
    label: "Study date",
    isSorting: true,
  },
  {
    id: "protein",
    numeric: true,
    disablePadding: false,
    label: "Accession Number",
  },
  // {
  //   id: "price",
  //   numeric: true,
  //   disablePadding: false,
  //   label: "#Reports",
  // }
  {
    id: "series_count",
    numeric: true,
    disablePadding: false,
    label: "#Series",
  },
];

interface EnhancedTableProps {
  numSelected: number;
  onRequestSort: (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => void;
  // onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const {
    // onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler =
    (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, property);
    };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "left" : "left"}
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
            sx={{ fontSize: "14px", fontWeight: "bold" }}
          >
            {headCell.isSorting ? (
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
                IconComponent={ShortingArrow}
              >
                <span>{headCell.label} &nbsp; </span>
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc"
                      ? "sorted descending"
                      : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              <span>{headCell.label}</span>
            )}
          </TableCell>
        ))}
        {/* <TableCell sx={{ verticalAlign: "bottom"}} align="right"><a href=""  style={{color:"#2FAED7"}}>Select columns</a></TableCell> */}
      </TableRow>
    </TableHead>
  );
}

interface EnhancedTableToolbarProps {
  numSelected: number;
}

const DropdownIconButton = (props: any) => {
  const { className } = props;
  return (
    <Box className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="5" fill="#CC8552" />
        <path
          d="M11.9832 13.2768L8.20119 9.49285C8.06543 9.36695 7.88612 9.29862 7.70101 9.30224C7.51589 9.30587 7.3394 9.38116 7.20867 9.51227C7.07794 9.64339 7.00317 9.82011 7.0001 10.0052C6.99702 10.1904 7.06588 10.3695 7.19219 10.5048L11.4762 14.7918C11.6061 14.9214 11.781 14.9959 11.9645 14.9998C12.1479 15.0037 12.3258 14.9368 12.4612 14.8128L16.7752 10.5128C16.8417 10.4465 16.8945 10.3678 16.9306 10.281C16.9666 10.1943 16.9853 10.1013 16.9854 10.0074C16.9856 9.91348 16.9672 9.82045 16.9314 9.73362C16.8956 9.64679 16.843 9.56786 16.7767 9.50135C16.7104 9.43483 16.6316 9.38203 16.5449 9.34596C16.4582 9.30989 16.3652 9.29125 16.2712 9.29111C16.1773 9.29097 16.0843 9.30933 15.9975 9.34515C15.9106 9.38096 15.8317 9.43353 15.7652 9.49985L11.9832 13.2768Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};

const DropdownAvailableIconButton = (props: any) => {
  const { className } = props;
  return (
    <Box className={className}>
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="24" height="24" rx="5" fill="#7ABA56" />
        <path
          d="M11.9832 13.2768L8.20119 9.49285C8.06543 9.36695 7.88612 9.29862 7.70101 9.30224C7.51589 9.30587 7.3394 9.38116 7.20867 9.51227C7.07794 9.64339 7.00317 9.82011 7.0001 10.0052C6.99702 10.1904 7.06588 10.3695 7.19219 10.5048L11.4762 14.7918C11.6061 14.9214 11.781 14.9959 11.9645 14.9998C12.1479 15.0037 12.3258 14.9368 12.4612 14.8128L16.7752 10.5128C16.8417 10.4465 16.8945 10.3678 16.9306 10.281C16.9666 10.1943 16.9853 10.1013 16.9854 10.0074C16.9856 9.91348 16.9672 9.82045 16.9314 9.73362C16.8956 9.64679 16.843 9.56786 16.7767 9.50135C16.7104 9.43483 16.6316 9.38203 16.5449 9.34596C16.4582 9.30989 16.3652 9.29125 16.2712 9.29111C16.1773 9.29097 16.0843 9.30933 15.9975 9.34515C15.9106 9.38096 15.8317 9.43353 15.7652 9.49985L11.9832 13.2768Z"
          fill="white"
        />
      </svg>
    </Box>
  );
};
const DownArrowIcon = (props: any) => {
  const { className } = props;
  return (
    <svg
      className={className}
      width="10"
      height="6"
      viewBox="0 0 10 6"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.98319 4.27685L1.20119 0.492846C1.06543 0.366946 0.886124 0.298618 0.701007 0.302242C0.51589 0.305865 0.339397 0.381158 0.208669 0.512274C0.0779409 0.643391 0.00317272 0.820107 9.86676e-05 1.00523C-0.00297538 1.19036 0.0658844 1.36946 0.192187 1.50485L4.47619 5.79185C4.60612 5.92143 4.78101 5.99593 4.96448 5.99984C5.14794 6.00375 5.32585 5.93677 5.46119 5.81285L9.77519 1.51285C9.8417 1.44653 9.8945 1.36776 9.93058 1.28104C9.96665 1.19431 9.98529 1.10133 9.98542 1.00741C9.98556 0.91348 9.9672 0.820446 9.93139 0.733616C9.89557 0.646786 9.843 0.567861 9.77669 0.501346C9.71037 0.434832 9.6316 0.38203 9.54488 0.345958C9.45815 0.309885 9.36517 0.291247 9.27125 0.291108C9.17732 0.290968 9.08429 0.30933 8.99746 0.345146C8.91063 0.380961 8.8317 0.433528 8.76519 0.499846L4.98319 4.27685Z"
        fill="black"
      />
    </svg>
  );
};

const formattedDate = (dateString: string) => {
  if (!dateString) {
    return "";
  }
  return moment(new Date(dateString)).format("DD-MMM-YYYY");
};

function Row(props: {
  index: number;
  row: any;
  toggleExpanded: (study_id: string) => void;
  expandedSeriesData: any;
  expandedItems: any;
  onDelete: (parentscanId: any, rowNumber: any, storeId?: string, row?: any, isPatientDelete?: boolean) => void;
  deleteMultipleSeries: any;
  checkAllSeries: any;
  onSelectRowSeries: any;
  seletAllDelete: any;
  fetchViewerUrls: any;
  fetchNotReviewedData: any;
  setSelectedDoctorItem: any;
  selectedDoctorItem: any;
  recommendedDoctorItem: any;
  setRecommendedDoctorItem: any;
  setFilterValue: any,
  handleDirectLinkChangeForScan: any,
  isAllowDirectLink: boolean,
  isDirectLinkEnabled: boolean,
  setAllowDirectLink:any
}) {
  const {
    index,
    row,
    toggleExpanded,
    expandedSeriesData,
    expandedItems,
    deleteMultipleSeries,
    checkAllSeries,
    onSelectRowSeries,
    seletAllDelete,
    fetchViewerUrls,
    fetchNotReviewedData,
    // setSelectedDoctorItem,
    // selectedDoctorItem,
    recommendedDoctorItem,
    setRecommendedDoctorItem,
    setFilterValue,
    handleDirectLinkChangeForScan,
    isAllowDirectLink,
    isDirectLinkEnabled,
    setAllowDirectLink
  } = props;
  const [open, setOpen] = React.useState(false);
  const [series, setSeries] = React.useState(false);
  const [metadata, setMetaData] = React.useState<any>({});
  const [isMetaLoading, setMetaLoading] = React.useState<boolean>(false);


  const [isAutoPopulate, setIsautoPopulate] = useState({
    patient: false,
    doctor: false,
  });
  const [isShowSharDialog, setShowSharDialog] = useState<boolean>(false);
  const [isShowReportDialog, setShowReportDialog] = useState<boolean>(false);
  const [mediaArray, setMediaArray] = useState<Array<any>>([]);
  const [linkReports, setLinkReports] = useState<Array<any>>([]);

  const [selectedPatient, setSelectedPatient] = useState<any>(null);
  const [attachedPatient, setAttachedPatient] = useState<any>(null);
  const [isAttachedPatientLoading, setAttachedPatientLoading] = useState<boolean>(false);

  const [selectedDoctor, setSelectedDoctor] = useState<any>(null);
  const [autoPopulateData, setautoPopulateData] = useState({});
  const [sharingStatus, setSharingStatus] = useState("AVAILABLE");
  const [cookies, setCookie, removeCookie] = useCookies();
  const dispatch = useDispatch();

  const [isAutoPopulateLoading, setIsAutoPopulateLoading] = useState(false);
  const [selectedDoctorItem, setSelectedDoctorItem] = useState<any>([]);
  // const [recommendedDoctorItem, setRecommendedDoctorItem] = useState<any>([]);
  // const [existingDoctorItem, setExistingDoctorItem] = useState<any>([]);
  const [confirmLoading, setConfirmLoading] = useState<any>({});

  const [isSendLoading, setIsSendLoading] = useState<boolean>(false);
  const [isAttachLoading, setAttachLoading] = useState<boolean>(false);

  const [isShowDownload, setShowDownload] = useState(false);
  const [isDownLoading, setDownlading] = useState(false);
  const [downloadingData, setDownloadingData] = useState({});
  const [isPDf, setIsPdf] = useState(false);
  const [downloadingImageData, setDownloadingImageData] = useState({});
  const [scansAuthToken, setScansAuthToken] = useState("");
  const [autoPopulateSourceToken, setAutoPopulateSource] = useState<any>("");
  const [existingRecipioents, setExistingRecipioents] = useState([]);
  const [isRecipientsLoading, setRecipientsLoading] = useState(false);
  const [isReportsLoading, setReportsLoading] = useState(true);

  const [isShowDeleteAttachment, setShowDeleteAttachment] =
    useState<boolean>(false);
  const [isDeleteAttachmentLoading, setDeleteAttachmentLoading] =
    useState<boolean>(false);
  const [DeleteAttachmentProps, setDeleteAttachmentProps] = useState<any>(null);
  const [attachedId, setAttachedId] = useState<any>(null);

  const studyDownloadRef: any = useRef<HTMLDivElement>();
  const [tableIcons, setTableIcons] = useState<any>(false);
  // useEffect(() => {
  // }, [mediaArray, selectedDoctorItem]);

  // useEffect(() => {
  //   // fetchMetaData()
  // }, []);

  const fetchMetaData = async () => {
    setMetaLoading(true)
    const token = cookies["access_token"];
    const countryData = cookies["countryData"];
    const url = `${API_URL}/unsent/study/${row.study_id}/metadata?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);

      if (response?.data?.status === "success") {
        const result = response?.data?.result;
        const finalData = result.reduce((acc: any, val: any) => {
          return { ...acc, ...val };
        });

        // const scansArray = Object.values(result);
        setMetaData(finalData);
        setMetaLoading(false)

      }
    } catch (error) {
      console.error(error);
      setMetaLoading(false)

    }
  };

  const fetchLinkReports = async (id = "") => {
    setReportsLoading(true)
    const token = cookies["access_token"];
    const url = `${API_URL}/list_data/scans/${row.fhir_resource_id
      }/report?patient_id=${attachedPatient?.id || id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);

      if (response?.data?.status === "success") {
        const result = response?.data?.data;


        setMediaArray(result);
        setReportsLoading(false)

        // const finalData = result.reduce((acc: any, val: any) => { return { ...acc, ...val } })

        // const scansArray = Object.values(result);
        // setMetaData(finalData);
      }
    } catch (error) {
      console.error(error);
      setReportsLoading(false)

    }
  };

  const unLink = async (id: string, type: string) => {
    setAttachedId(id);
    onShowDeleteAttachment(type, id);
  };

  const deleteLinkReport = async (id: string) => {
    setDeleteAttachmentLoading(true);
    const token = cookies["access_token"];
    const url = `${API_URL}/add_data/scans/report/${id}/unlink`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.patch(url, {}, config);

      if (response?.data?.status === "success") {
        const result = response?.data?.data;
        fetchLinkReports();
        setDeleteAttachmentLoading(false);
        setShowDeleteAttachment(false);
      }
    } catch (error) {
      console.error(error);
      setDeleteAttachmentLoading(false);
    }
  };

  const deleteLinkDoctor = async (id: string) => {
    setDeleteAttachmentLoading(true);
    const token = cookies["access_token"];
    const url = `${API_URL}/unsent/patient/${attachedPatient?.id}/scans/${row.fhir_resource_id}/consent/doctor/${id}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(url, config);

      if (response?.data?.status === "success") {
        const result = response?.data?.data;
        fetchExistingRecipients();
        setDeleteAttachmentLoading(false);
        setShowDeleteAttachment(false);
      }
    } catch (error) {
      console.error(error);
      setDeleteAttachmentLoading(false);
    }
  };

  const getReviewedStudyData = async (recordFetchId: string) => {
    setAttachedPatientLoading(true);
    const token = cookies["access_token"];
    const reportUrl = `${API_URL}/list_data/study?study_fhir_id=${recordFetchId || row.fhir_resource_id
      }`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(reportUrl, config)
      .then((response) => {
        if (response && response.data && response.data.data) {
          const patientData = response.data.data.patient_details
          const studyStatus = response.data.data.study_info.status
          patientData["patient_fhir_id"] = response?.data?.data?.patient_details?.id || null
          setAttachedPatientLoading(false);
          setAttachedPatient(patientData);
          setSharingStatus(studyStatus);
        } else {
          alert("Something went wrong!");
          setAttachedPatientLoading(false);

        }
      })
      .catch((error) => {
        console.log(error);
        setAttachedPatientLoading(false);
      });
  };

  const fetchDataForDownload = (downloadStudyId: string) => {
    setDownlading(true);
    const token = cookies["access_token"];
    const Url = `${API_URL}/list_data/study/pamphlet?study_fhir_id=${downloadStudyId}`;
    const scansImagesUrl = `${API_URL}/list_data/frame_urls?study_fhir_id=${downloadStudyId}`;

    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(Url, config)
      .then((response) => {
        if (response && response.data && response.data.status === "success") {
          setDownloadingData(response?.data?.data);

          axios.get(scansImagesUrl, config).then(async (responseData) => {
            if (
              responseData &&
              responseData.data &&
              responseData.data.status === "success"
            ) {
              setScansAuthToken(responseData.data.data.auth_token);

              const frames = await Object.keys(
                responseData.data.data.frame_urls
              )
                ?.slice(0, 2)
                .map((frame: any) => responseData.data.data.frame_urls[frame]);
              const fetchFrameImages = async () => {
                const framesPromise = frames.map(async (frameArray) => {
                  const fetchProtectedImage = frameArray.map(
                    async (itemUrl: any) => {
                      let url = await displayProtectedImage(
                        `${itemUrl}`,
                        responseData.data.data.auth_token
                      );
                      let base64 = await blobToBase64(url);
                      return base64;
                    }
                  );
                  const fetchProtectedImageArr = await Promise.all(
                    fetchProtectedImage
                  );
                  return fetchProtectedImageArr;
                });
                return await Promise.all(framesPromise);
              };

              fetchFrameImages().then((fetchProtectedImageArr: any) => {
                setDownloadingImageData(fetchProtectedImageArr);
                setIsPdf(true);
                setTimeout(() => {
                  studyDownloadRef.current.onDownload();
                  setTimeout(() => {
                    setShowDownload(false);
                  }, 2000);
                }, 3000);
              });
            } else {
              setIsPdf(true);
              setTimeout(() => {
                studyDownloadRef.current.onDownload();
                setShowDownload(false);
              }, 3000);
            }
          });
        } else {
          setShowDownload(false);
          setDownlading(false);
          setDownloadingData({});
          setIsPdf(false);

          alert("Something went wrong!");
        }
      })
      .catch((error) => {
        console.log(error);
        setShowDownload(false);
        setIsPdf(false);

        setDownlading(false);
        setDownloadingData({});
      });
  };

  const getDoctorIds = () => {
    let doctorIds = selectedDoctorItem.map(
      (doctor: any) => doctor.doctor_id || doctor.patient_fhir_id
    );
    if (selectedDoctor && selectedDoctor.id) {
      doctorIds.push(selectedDoctor.id);
    }
    return doctorIds;
  };

  const recordStatusChange = async (
    type: string,
    id: string,
    status: string
  ) => {
    setShowDeleteAttachment(true);
    let AttachmentDeleteModalprops = {};
    if (type === "report") {
      AttachmentDeleteModalprops = {
        onDelete: () => handleRecordStatusChange(type, id, status),
        testId: "test",
        title: "Are you sure ?",
        discription:
          status === "RESTRICTED"
            ? "You are changing the report status. The patient will lose access to the file."
            : "You are changing the report status. The patient will get access to the report and will be notified.",
        actionButtonText: "Confirm",
        loadingText: "Status Changing...",
      };
    } else if (type === "scan") {
      AttachmentDeleteModalprops = {
        onDelete: () => handleRecordStatusChange(type, id, status),
        testId: "test",
        title: "Are you sure ?",
        discription:
          status === "RESTRICTED"
            ? "You are changing the scan sharing status. The patient will lose access to the file."
            : "You are changing the scan sharing status. The patient will get access to the scan and will be notified.",
        actionButtonText: "Confirm",
        loadingText: "Status Changing...",
      };
    }

    setDeleteAttachmentProps(AttachmentDeleteModalprops);
  };

  const handleRecordStatusChange = async (
    type: string,
    id: string,
    status: string
  ) => {
    setDeleteAttachmentLoading(true);
    const token = cookies["access_token"];
    const payload_report = {
      record_type: type,
      id,
      status,
    };
    try {
      const response = await axios.post(
        `${API_URL}/add_data/status/update`,
        payload_report,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response && response.data.status === "success") {
        if (type === "report") {
          fetchLinkReports();
        } else {
          fetchNotReviewedData()
          .then((data: any) => {
          getReviewedStudyData(row.fhir_resource_id);
          })
        }
        setTimeout(() => {
          setDeleteAttachmentLoading(false);
          setShowDeleteAttachment(false);
        }, 2000);

        dispatch(
          setShowStatus({
            message: "Status updated...",
            severity: "success",
          })
        );
      } else {
        setDeleteAttachmentLoading(false);
        alert("Something went wrong!");
      }
    } catch (error) {
      setDeleteAttachmentLoading(false);
      console.log(error);
    }
  };

  const attchReports = async (data: any) => {
    setAttachLoading(true);
    const token = cookies["access_token"];
    if (data.length) {
      const payload_report = {
        patient_id: attachedPatient.id,
        // doctor_ids: doctorIds,
        report_info: data,
        scan_id: row.fhir_resource_id,
      };

      const response = await axios.post(
        `${API_URL}/add_data/report/add`,
        payload_report,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response && response.data.status === "success") {
        fetchLinkReports();
        setAttachLoading(false);
        setShowReportDialog(false);

        // if (selectedDoctorItem && Object.keys(selectedDoctorItem)?.length) {
        //   localStorage.setItem(
        //     "lastShareDoctorItem",
        //     JSON.stringify(selectedDoctorItem)
        //   );
        // }

        dispatch(
          setShowStatus({
            message: "Reports attached successfully",
            severity: "success",
          })
        );
      } else {
        setAttachLoading(false);
        alert("Something went wrong!");
      }
    }
  };

  const fetchExistingRecipients = () => {
    setRecipientsLoading(true);
    const token = cookies["access_token"];
    const endpointUrl = `${API_URL}/share/users`;
    let data = JSON.stringify({
      patient_id: attachedPatient?.id || "",
      report_type: "scans",
      resource_id: row.fhir_resource_id,
    });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    axios
      .post(endpointUrl, data, config)
      .then((response: any) => {
        if (
          response.data &&
          response.data?.status == "success" &&
          response.data?.result
        ) {
          setExistingRecipioents(response.data?.result);
          setRecipientsLoading(false);
        } else {
          alert("Something went wrong!!");
          setRecipientsLoading(false);
        }
      })
      .catch((error) => {
        console.log(error);
        setRecipientsLoading(false);
      });
  };

  const confirlAll = async (doctorIdList?:Array<string>) => {
    const patientId = attachedPatient.id || "";
    const token = cookies["access_token"];
    const doctorIdsSet = doctorIdList?.length ? new Set(doctorIdList) : new Set(getDoctorIds());
    const doctorIds = Array.from(doctorIdsSet);
    setIsSendLoading(true);
console.log("confirm all",doctorIds);

    try {
      const Url = `${API_URL}/share`;
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const shareAll = doctorIds.map((id: any) => {
        const payload = {
          patient_id: patientId,
          grantee_id: id,
          grantee_type: "Practitioner",
          report_type: "scans",
          resource_id: row.fhir_resource_id,
          scan_info: {
            modality: [row?.modality_list],
            study_date: row?.study_timestamp,
            location: row?.location,
            description: row?.study_description,
          },
        };
        return axios.post(Url, payload, config).then((response) => { return response }).catch((error) => error);
      });
      setIsSendLoading(true);

      return Promise.all(shareAll).finally(() => {
        fetchExistingRecipients();
        fetchLinkReports();
        setSelectedDoctorItem([]);
        if(!doctorIdList?.length){
          setRecommendedDoctorItem([]);
        }
        setIsSendLoading(false);
        return true
      }).catch((err) => {
        console.error("Failed share report:", err);
        fetchExistingRecipients();
        return err
      });
    } catch (error) {
      console.error("Failed to share report:", error);
      setIsSendLoading(false);
      return error

    }
  };

  useEffect(() => {
    if (expandedItems[row.study_id]) {
      if (!row.is_sent) {
        fetchAutoPopulateData();
        setAllowDirectLink(false)
      } else {
        getReviewedStudyData(row.fhir_resource_id);
      }
      fetchMetaData();
    } else {
      setSelectedDoctorItem([]);
      setRecommendedDoctorItem([]);
      setExistingRecipioents([]);
      setMediaArray([]);
      setAllowDirectLink(false)
    }
  }, [expandedItems]);

  useEffect(() => {
    if (attachedPatient?.id) {
      fetchExistingRecipients();
      fetchLinkReports();
    }
    if (row.is_sent) {
      setExistingRecipioents([]);
      setautoPopulateData({});
      setIsautoPopulate({
        patient: false,
        doctor: false,
      });
    }
  }, [attachedPatient, row.is_sent]);

  const fetchAutoPopulateData = async () => {
    setIsAutoPopulateLoading(true);
    const token = cookies["access_token"];
    const url = `${API_URL}/unsent/study/extract`;
    const autoPopulateSource = axios.CancelToken.source();
    setAutoPopulateSource(autoPopulateSource);
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
      cancelToken: autoPopulateSource.token,
    };
    const payload = {
      study_url: row?.study_url,
    };
    const payloadDiacomPath = {
      dicom_path: row?.dicom_path,
      study_id: row?.study_id,
    };
    try {
      const response = await axios.post(
        url,
        row?.study_url ? payload : payloadDiacomPath,
        config
      );
      if (response?.data?.status === "success") {
        setautoPopulateData(response.data.data);

        setIsautoPopulate({
          patient: Object.keys(response.data.data?.patient_data)?.length
            ? true
            : false,
          doctor: response.data.data?.doctor_data?.id ? true : false,
        });
        setIsAutoPopulateLoading(false);
        if (response?.data?.data) {
          setSelectedDoctor({
            id: response.data.data?.doctor_data?.id || null,
          });
          setSelectedPatient({
            id: response.data.data?.patient_data?.patient_fhir_id || null,
          });
          setRecommendedDoctorItem(
            (Object.keys(response.data.data?.doctor_data).length &&
              [response.data.data?.doctor_data]) ||
            []
          );
        }
      }
    } catch (error) {
      console.error(error);
      setautoPopulateData({});
      setIsautoPopulate({ patient: false, doctor: false });
      setIsAutoPopulateLoading(false);
    }
  };


  const onConfirm = async (item: any, type: string, index?: number) => {
    try {
      const token = cookies["access_token"];
      const sendUrl = `${API_URL}/unsent/study/send`;

      let payload_pacs;
      const doctor_id = item?.patient_fhir_id || item?.doctor_id || item?.id;
      if (type == "doctor") {
        setConfirmLoading({
          ...confirmLoading,
          [item?.patient_fhir_id || item?.doctor_id || item?.id]: true,
        });
      } else if (type == "patient") {
        setConfirmLoading({ ...confirmLoading, [item?.patient_fhir_id || selectedPatient?.id]: true });
      }
      if (doctor_id && type == "doctor") {
        const patientId = attachedPatient.id || "";
        const token = cookies["access_token"];
        const payload = {
          patient_id: patientId,
          grantee_id: doctor_id,
          grantee_type: "Practitioner",
          report_type: 'scans',
          resource_id: row.fhir_resource_id,
          scan_info: {
            modality: [row?.modality_list],
            study_date: row?.study_timestamp,
            location: row?.location,
            description: row?.study_description,
          },
        };
        try {
          const Url = `${API_URL}/share`;
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.post(Url, payload, config);
          if (response && response.data.status === "success") {
            setSelectedDoctorItem((prevItems: any) => prevItems.length ? prevItems.filter((item: any) => ((item.patient_fhir_id || item.doctor_id) !== doctor_id)) : []);
            setRecommendedDoctorItem((prevItems: any) => prevItems.length ? prevItems.filter((item: any) => ((item.id) !== doctor_id)) : []);


            // setConfirmLoading((prevItem:any) => {
            //   return {[prevItem[doctor_id]]:false}

            // })

            setConfirmLoading({ ...confirmLoading, [doctor_id]: false })

            fetchExistingRecipients()
            dispatch(
              setShowStatus({
                message: "Scan share to doctor successfully",
                severity: "success",
              })
            )

          } else {
            console.error("Failed to share report:");
            setConfirmLoading({ ...confirmLoading, [doctor_id]: false })

          }
        } catch (error: any) {
          console.error("Failed to share report:", error);
          dispatch(
            setShowStatus({
              message: error.response.data.message || "something went wrong",
              severity: "error",
            })
          );

          setConfirmLoading({ ...confirmLoading, [doctor_id]: false })
        }


      } else {
        setAttachedPatient({})
        payload_pacs = {
          file_info: {
            study_id: row?.study_id,
            study_path: row?.dicom_path,
            status: sharingStatus,
            direct_viewer_link: isAllowDirectLink,
          },
          patient_id: item?.patient_fhir_id || selectedPatient?.id,
        };
        const response = await axios.post(sendUrl, payload_pacs, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response && response.data.status === "success") {
          // if (selectedDoctorItem && Object.keys(selectedDoctorItem)?.length) {
          //   localStorage.setItem(
          //     "lastShareDoctorItem",
          //     JSON.stringify(selectedDoctorItem)
          //   );
          // }
          setAllowDirectLink(false)
          setFilterValue({
            name: "",
            patient_id: "",
            accession_number: "",
            patientAttached: "",
            sharingStatus:"",
            modality: [],
            studyDate: "",
            accessNumber: "",
          })
          fetchNotReviewedData()
            .then((data: any) => {
              getReviewedStudyData(response.data.data.scan_id).then(
                (data: any) => {
                  setTimeout(() => {
                    setConfirmLoading({});
                    fetchExistingRecipients();
                    fetchLinkReports();
                  }, 1000);
                }
              );

              setIsSendLoading(false);
              setConfirmLoading({});
              
            })
            .catch((err: any) => { });


          dispatch(
            setShowStatus({
              message: "Scan file sent successfully",
              severity: "success",
            })
          );
          dispatch(
            setShowStatus({
              message: "Notification Sent Successfully.",
              severity: "success",
            })
          );

          
        } else {
          setIsSendLoading(false);
          alert("Something went wrong!");
          setConfirmLoading({
            ...confirmLoading,
            [item?.patient_fhir_id || item?.doctor_id]: false,
          });
          setConfirmLoading({ ...confirmLoading, [item?.patient_fhir_id || selectedPatient?.id]: false });
        }
      }
    } catch (error) {
      console.error(error);
      setIsSendLoading(false);
      setConfirmLoading({
        ...confirmLoading,
        [item?.patient_fhir_id || item?.doctor_id]: false,
      });
      setConfirmLoading({ ...confirmLoading, [item?.patient_fhir_id || selectedPatient?.id ]: false });
    }
  };


  const reShareModal = async (
    item: any,
    type: string,
    status: string
  ) => {
    let AttachmentDeleteModalprops = {};
    setAllowDirectLink(false)
    if (type === "patient") {
    setAllowDirectLink(false)
      AttachmentDeleteModalprops = {
        onDelete: () => onReShare(item, type),
        testId: "test",
        title: "Are you sure ?",
        discription:
          status === "RESTRICTED"
            ? "You are about to re-share the link. The patient will not be notified because of restricted sharing status."
            : "You are about to re-share the link. The patient will be notified.",
        actionButtonText: "Confirm",
        loadingText: "Re-sharing...",
        isShowDirectLinkCheckbox: true,
        reShareData:{
          item, type
        }
      };
    } else if (type === "doctor") {
      AttachmentDeleteModalprops = {
        onDelete: () => onReShare(item, type,),
        testId: "test",
        title: "Are you sure ?",
        discription:"You are about to re-share the link. The doctor will be notified.",
        actionButtonText: "Confirm",
        loadingText: "Re-sharing...",
      };
    }
    setShowDeleteAttachment(true);

    setDeleteAttachmentProps(AttachmentDeleteModalprops);
  };

  const onReShare = async (item: any, type: string, isDirectLink?: boolean) => {
    try {
      const token = cookies["access_token"];
      const sendUrl = `${API_URL}/unsent/notification/resend`;

      let payload_pacs;
      const doctor_id = item?.grantee_id

      setDeleteAttachmentLoading(true);

      if (doctor_id && type == "doctor") {
        const patientId = attachedPatient.id || "";
        const token = cookies["access_token"];
        const payload = {
          patient_id: patientId,
          recipient_id: doctor_id,
          recipient_type: "Practitioner",
          scan_data: {
            id: row.fhir_resource_id,
            modality: [row?.modality_list],
            study_date: row?.study_timestamp,
            location: row?.location,
            description: row?.study_description,
          }
        };
        try {
          const config = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await axios.post(sendUrl, payload, config);
          if (response && response.data.status === "success") {
            fetchExistingRecipients()
            fetchLinkReports()
            dispatch(
              setShowStatus({
                message: "File shared successfully",
                severity: "success",
              })
            );
            setDeleteAttachmentLoading(false);
            setShowDeleteAttachment(false);
            setAllowDirectLink(false)

          } else {
            console.error("Failed to share report:");
            setDeleteAttachmentLoading(false);


          }
        } catch (error: any) {
          console.error("Failed to share report:", error);
          dispatch(
            setShowStatus({
              message: error.response.data.message || "something went wrong",
              severity: "error",
            })
          );

          // setConfirmLoading({ ...confirmLoading, [doctor_id]: false })
          setDeleteAttachmentLoading(false);

        }


      } else {

        payload_pacs = {
          patient_id: attachedPatient.id,
          recipient_id: attachedPatient.id,
          recipient_type: "patient",
          scan_data: {
            id: row.fhir_resource_id,
            modality: [row?.modality_list],
            study_date: row?.study_timestamp,
            location: row?.location,
            description: row?.study_description,
          },
          direct_viewer_link: isDirectLink,

        };
        const response = await axios.post(sendUrl, payload_pacs, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        });
        if (response && response.data.status === "success") {
          fetchNotReviewedData()
            .then((data: any) => {
              getReviewedStudyData(response.data.data.scan_id).then(
                (data: any) => {
                  setTimeout(() => {
                    setConfirmLoading({});
                  }, 1000);
                }
              );
              fetchExistingRecipients();
              fetchLinkReports();
            })
            .catch((err: any) => { });
          setIsSendLoading(false);
          // setConfirmLoading({});
          setDeleteAttachmentLoading(false);
          setShowDeleteAttachment(false);
          // dispatch(
          //   setShowStatus({
          //     message: "File shared successfully",
          //     severity: "success",
          //   })
          // );
        } else {
          setIsSendLoading(false);
          alert("Something went wrong!");
          setDeleteAttachmentLoading(false);

        }
      }
    } catch (error: any) {
      console.error(error);
      setIsSendLoading(false);

      setDeleteAttachmentLoading(false);

      dispatch(
        setShowStatus({
          message: error.response.data.message || "something went wrong",
          severity: "error",
        })
      );
    }
  };



  const onShowDeleteAttachment = (type: string, id: string) => {
    setShowDeleteAttachment(true);
    let AttachmentDeleteModalprops = {};
    if (type === "report") {
      AttachmentDeleteModalprops = {
        onDelete: () => deleteLinkReport(id),
        testId: "test",
        title: "Are you sure ?",
        discription:
          "The action cannot be undone. The reports will be deleted from everywhere.",
        actionButtonText: "Delete",
      };
    } else if (type === "doctor") {
      AttachmentDeleteModalprops = {
        onDelete: () => deleteLinkDoctor(id),
        testId: "test",
        title: "Are you sure ?",
        discription:
          "Removing a doctor will revoke their access to the scan as well as the related reports.",
        actionButtonText: "Delete",
      };
    }

    setDeleteAttachmentProps(AttachmentDeleteModalprops);
  };

  const onDownload = async (downloadStudyId: string) => {
    setShowDownload(true);
    fetchDataForDownload(downloadStudyId);
  };

  function blobToBase64(blob: any) {
    return new Promise((resolve, _) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.readAsDataURL(blob);
    });
  }

  async function displayProtectedImage(imageUrl: any, token: any) {
    const responses = await fetch(imageUrl, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
        Accept: "Image/jpeg",
      },
    });
    let dataaaa = await responses.blob();
    return dataaaa;
  }
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const DropdownOpen = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "& > *": {
            borderBottom: "unset",
            background: expandedItems[row.study_id] ? "#F0F0F0" : "",
            padding: "16px 16px",
          },
        }}
        onMouseOver={() => setTableIcons(true)}
        onMouseLeave={() => setTableIcons(false)}

      >

        <TableCell component="th" scope="row" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>
         <UserName style={{ fontSize:"0.875rem",maxWidth: "140px",color:'#000000de'}}>{row.patient_name}</UserName> 
        </TableCell>
        <TableCell component="th" scope="row" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>
          {row.patient_id || "-"}
        </TableCell>
        <TableCell align="left" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>
         
          <Typography >
            <Chip sx={{fontSize:"0.875rem",
            background:row?.fhir_resource_status == "AVAILABLE" || row?.fhir_resource_status== null ? '#32cd3247' : '#FCD6BB',
             color:row?.fhir_resource_status == "AVAILABLE" || row?.fhir_resource_status== null ? "#0D5C37" : "#7F380A"}} 
             label={row?.fhir_resource_status == "AVAILABLE" || row?.fhir_resource_status== null ? "AVAILABLE" : "RESTRICTED"} />
           
          </Typography>
        </TableCell>
        <TableCell align="left" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>

          <Typography >
            <Chip sx={{fontSize:"0.875rem",
            minWidth:'70px',
            background:row?.is_sent ? '#32cd3247' : '#FCD6BB',
             color:row?.is_sent ? "#0D5C37" : "#7F380A"}} 
             label= {row?.is_sent ? "Yes" : "No"} />
          </Typography>
         
         
        </TableCell>
        <TableCell align="left" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>{row.modality_list}</TableCell>
        <TableCell align="left" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>
          {" "}
          {formattedDate(row?.study_timestamp) || "-"}
        </TableCell>
        <TableCell align="left" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>{row.accession_number || "-"}</TableCell>
        <TableCell align="left" onClick={() => {
          toggleExpanded(row?.study_id);
        }}>{row.series_count}</TableCell>

        {/* <TableCell align="left" onClick={() => {
          toggleExpanded(row?.study_id);
        }}></TableCell> */}

        <TableCell
          align="right"
          sx={{ display: "flex", alignItems: "center", borderBottom: "none" }}
        >
          <IconButton aria-label="expand row" size="small" onClick={() => {
            toggleExpanded(row?.study_id);
          }}>
            {expandedItems[row.study_id] ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </IconButton>
          <Box sx={{ width: "200px" }}>
            {expandedItems[row.study_id] || tableIcons ? (
              <Box className="tableActiveBtn">
                <Box
                  display={"flex"}
                  sx={{
                    gap: "10px",
                    alignItems: "center",
                    marginRight: "20px",
                    justifyContent: "end"
                  }}
                >
                  <Button
                    sx={{
                      background: '#fff',
                      width: "100%",
                      maxWidth: '100px',
                      border: "1px solid #808080",
                      padding: "3px 15px",
                      borderRadius: "5px",
                      display: "flex",
                      justifyContent: "space-between",
                      gap: "4px",
                      color: "#000"
                    }}
                    id="basic-button"
                    aria-controls={DropdownOpen ? "basic-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={DropdownOpen ? "true" : undefined}
                    onClick={handleClick}
                  >
                    View
                    <ArrowImageInstance src={DownArrowBlack} />

                  </Button>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={DropdownOpen}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                      style: { paddingBottom: '0px', paddingTop: '0px' }
                      ,
                    }}
                  >
                    <MenuItem
                      onClick={() => {
                        fetchViewerUrls(row, "basic", "study")
                      }}
                      sx={{ padding: "0" }}
                    >
                      <Dropdownlist>
                        {" "}
                        <Box>
                          <img src={OHIFIcon} />
                        </Box>
                        OHIF Viewer
                      </Dropdownlist>
                    </MenuItem>
                    <MenuItem sx={{ padding: "0" }} onClick={() => {
                      fetchViewerUrls(row, "tmtv", "study")
                    }}>  <Dropdownlist><Box>
                      <img src={TMTVIcon} />
                    </Box>
                        TMTV Viewer
                      </Dropdownlist>
                    </MenuItem>
                    <MenuItem sx={{ padding: "0" }} onClick={() => {
                      fetchViewerUrls(row, "medDream", "study")
                    }}>  <Dropdownlist><Box>
                      <img src={TMTVIcon} />
                    </Box>
                    MedDream Viewer
                      </Dropdownlist>
                    </MenuItem>
                  </Menu>

                  {row.is_sent && (
                    <ActionBtn
                      backgroundColor="#0099CC"
                      imgSrc={DownloadIcon}
                      label=""
                      onClick={() => {
                        onDownload(row?.fhir_resource_id)
                      }}
                    />
                  )}
                  <ActionBtn
                    backgroundColor="#CC8552"
                    imgSrc={DeleteIcons}
                    label=""
                    onClick={() => {
                      props.onDelete(row.study_id, index, row.store_id, row);
                    }}
                  />
                </Box>
              </Box>
            ) : (
              ""
            )}
          </Box>
        </TableCell>
      </TableRow>

      {/* study colapse section  */}
      <TableRow sx={{ background: "#F0F0F0" }}>
        <TableCell
          style={{ paddingBottom: 0, paddingTop: 0, background: "#F0F0F0" }}
          colSpan={10}
        >
          <Collapse
            in={expandedItems[row.study_id]}
            timeout="auto"
            unmountOnExit
          >
            <Box
              sx={{
                margin: "0 10px 10px 10px",
                padding: 1,
              }}
            >
              <Box
                display={"flex"}
                justifyContent={"start"}
                sx={{ marginBottom: "20px", gap: "16px" }}
              >
                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "8px",
                    width: "65%",
                  }}
                  padding={"20px"}
                >
                  {/* study detail section */}
                  <Box
                    sx={{
                      py: 2,
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      variant="inherit"
                      gutterBottom
                      component="div"
                      sx={{
                        color: "#0099CC",
                        fontSize: "16px",
                        cursor: "pointer",
                      }}
                      display={"flex"}
                      gap={"10px"}
                    >
                      Study details{" "}
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 20C4.47715 20 0 15.5228 0 10C0 4.47715 4.47715 0 10 0C15.5228 0 20 4.47715 20 10C20 15.5228 15.5228 20 10 20ZM10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM9 5H11V7H9V5ZM9 9H11V15H9V9Z"
                          fill="black"
                        />
                      </svg>
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        whiteSpace: "nowrap",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      Sharing Status
                      <FormControl
                        fullWidth
                        sx={{ minWidth: "130px", height: "36px" }}
                        size="small"
                      >
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          IconComponent={
                            sharingStatus == "AVAILABLE"
                              ? DropdownAvailableIconButton
                              : DropdownIconButton
                          }
                          onChange={(event) => {
                            if (row.is_sent) {
                              recordStatusChange(
                                "scan",
                                row.fhir_resource_id,
                                event.target.value
                              );
                            } else {
                              setSharingStatus(event.target.value);
                            }
                          }}
                          sx={{
                            ".MuiOutlinedInput-root": {
                              borderColor:
                                sharingStatus == "AVAILABLE"
                                  ? "#7ABA56"
                                  : "#CC8552",
                            },
                            ".MuiSelect-select": {
                              color:
                                sharingStatus == "AVAILABLE"
                                  ? "#7ABA56"
                                  : "#CC8552",
                            },
                            ".MuiSelect-icon": {
                              top: "calc(50% - 12px)",
                            },
                            ".MuiSelect-icon.MuiSelect-iconOpen": {
                              top: "calc(50% - 17px)",
                            },
                            "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                              borderColor:
                                sharingStatus == "AVAILABLE"
                                  ? "#7ABA56"
                                  : "#CC8552",
                              outline: "none", // Replace 'your-color' with the desired color
                            },
                            hover: {
                              outline: "none",
                              border:
                                sharingStatus == "AVAILABLE"
                                  ? "#7ABA56"
                                  : "#CC8552",
                            },
                            fieldset: {
                              borderColor:
                                sharingStatus == "AVAILABLE"
                                  ? "#7ABA56"
                                  : "#CC8552",
                            },
                          }}
                          value={sharingStatus}
                        >
                          <MenuItem value={"RESTRICTED"}>Restricted</MenuItem>
                          <MenuItem value={"AVAILABLE"}>Available</MenuItem>
                        </Select>
                      </FormControl>
                    </Box>
                  </Box>

                  {!isMetaLoading ? <StudyDetailsListBox>
                    <Typography variant="inherit" gutterBottom component="div">
                      <span className="listtitle">Institution Name</span>:
                      <span className="listData">
                        {metadata["Institution Name"] || "-"}
                      </span>
                    </Typography>
                    <Typography variant="inherit" gutterBottom component="div">
                      <span className="listtitle">Study Description</span> :
                      <span className="listData">
                        {metadata["Study Description"] || "-"}
                      </span>
                    </Typography>
                    <Typography variant="inherit" gutterBottom component="div">
                      <span className="listtitle">
                        Referring Physician's Name
                      </span>{" "}
                      :
                      <span className="listData">
                        {metadata["Referring Physician's Name"] || "-"}
                      </span>
                    </Typography>
                    <Typography variant="inherit" gutterBottom component="div">
                      <span className="listtitle">
                        Performing Physician's Name
                      </span>{" "}
                      :
                      <span className="listData">
                        {metadata["Performing Physician's Name"] || "-"}
                      </span>
                    </Typography>
                  </StudyDetailsListBox> : <div style={{ width: '100%' }}>
                    <SimpleLoader
                      size={30}
                      height="160px"
                    />
                  </div>

                  }
                </Box>

                {/* patient  search and attach section */}
                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "8px",
                    width: "35%",
                    maxHeight: "250px",
                  }}
                  padding={"20px"}
                >
                  <Box display={'flex'}justifyContent={'space-between'} >
                  <Typography
                    variant="inherit"
                    gutterBottom
                    paddingBottom={"19px"}
                    fontSize={"16px"}
                    fontWeight={"400px"}
                    sx={{ color: "#0099CC" }}
                  >
                    Patient
                  </Typography>

                  {isDirectLinkEnabled && !row.is_sent && selectedPatient?.id && (
                    <Box display="flex" alignItems="center" mb={2} ml={5} >
                      <div>
                        <Checkbox
                          checked={isAllowDirectLink}
                          sx={{ padding: 0 }}
                          onChange={handleDirectLinkChangeForScan}
                        />
                      </div>
                      <Box ml={1}>Allow Direct Link</Box>
                    </Box>
                  )}
                  </Box>
                  {!row.is_sent ? <>

                    {!isAutoPopulateLoading ? <PatientAttechedDropdown
                      type={"patient"}
                      isAutoPopulate={isAutoPopulate}
                      autoPopulateData={autoPopulateData}
                      setIsautoPopulate={setIsautoPopulate}
                      setautoPopulateData={setautoPopulateData}
                      setSelectedPatient={setSelectedPatient}
                      setSelectedDoctor={setSelectedDoctor}
                      setSelectedDoctorItem={setSelectedDoctorItem}
                      onConfirm={onConfirm}
                      confirmLoading={confirmLoading}
                      sharingStatus={sharingStatus}
                      isDirectLinkEnabled={isDirectLinkEnabled}
                      setAllowDirectLink={()=>setAllowDirectLink(!isAllowDirectLink)}
                      isAllowDirectLink={isAllowDirectLink}

                    /> :

                      <div style={{ marginTop: '10px', }}>
                        <PatentsCardRecommended>
                          <CloseIcon>
                            <img src={CloseIcons} onClick={() => autoPopulateSourceToken.cancel('Operation canceled by the user.')} />
                          </CloseIcon>
                          <PatentsCardRecommendedText>
                            <Box
                              display={"flex"}
                              justifyContent={"center"}
                              alignItems={'center'}
                              sx={{ gap: "16px", p: 2, height: "100px" }}
                            >
                              <Box>
                                <SimpleLoader height="14" text={"Populating Patient data"} />
                              </Box>
                            </Box>

                          </PatentsCardRecommendedText>
                          <Box display={"flex"}>
                            <RecommendedBtn
                              style={{
                                width: "100%",
                                height: "20px",
                                borderRadius: "0px 0px 12px 12px",
                              }}
                            >
                              {" "}
                            </RecommendedBtn>
                          </Box>
                        </PatentsCardRecommended>
                      </div>
                    }

                  </>
                    : (
                      attachedPatient?.id  ? <>
                        <PatentsCardRecommended>
                          <CloseIcon>
                            <img
                              src={CloseIcons}
                              onClick={() => {
                                props.onDelete(row.study_id, index, row.store_id, row, true);
                              }}
                            />
                          </CloseIcon>
                          <PatentsCardShareText>
                            <Box
                              display={"flex"}
                              justifyContent={"start"}
                              sx={{ gap: "16px", p: 2 }}
                            >
                              <PasentImage>
                                <img src={UserImage} />
                              </PasentImage>
                              <Box>
                                <Typography
                                  variant="inherit"
                                  gutterBottom
                                  sx={{ color: "#0099CC" }}
                                >
                                  {attachedPatient?.name}
                                </Typography>

                                <Typography variant="body1" gutterBottom>
                                  {attachedPatient?.phone_number}
                                </Typography>
                                <PatientData>{attachedPatient?.email}</PatientData>
                                <Typography
                                  variant="body1"
                                  gutterBottom
                                  sx={{ pt: "2", color: "#808080" }}
                                >
                                  {attachedPatient?.gender}
                                  {attachedPatient?.date_of_birth &&
                                    ", " +
                                    moment().diff(
                                      moment(
                                        attachedPatient?.date_of_birth,
                                        "YYYY-MMM-DD"
                                      ),
                                      "years"
                                    ) +
                                    " Years"}
                                </Typography>
                              </Box>
                            </Box>
                          </PatentsCardShareText>
                          <Box display={"flex"}>
                            <Reshare
                              onClick={() => reShareModal(attachedPatient, "patient", sharingStatus)}
                            disabled={sharingStatus === "RESTRICTED"}
                            >
                              <>
                                {confirmLoading[
                                  attachedPatient?.id
                                ] ? (
                                  <CircularProgress
                                    size={18}
                                    color="inherit"
                                  />
                                ) : (
                                  <>
                                    Re-share <img src={ReshareIcon} />
                                  </>
                                )}
                              </>
                            </Reshare>
                          </Box>
                        </PatentsCardRecommended>
                      </> :
                        <div style={{ width: '100%', marginTop: "10px" }}>
                          <SimpleLoader size={30} height="160px" />
                        </div>

                    )}
                </Box>
              </Box>

              {/* doctor sharing section */}

              <Box
                sx={{ background: "#fff", borderRadius: "8px", mb: 2 }}
                padding={"20px"}
              >
                <Box display={"flex"} justifyContent={"space-between"}>
                  <Typography
                    variant="inherit"
                    gutterBottom
                    sx={{ color: "#0099CC" }}
                  >
                    Sharing
                  </Typography>
                  {
                    <Box display={"flex"}>
                      <SuccessBtn
                        style={{ marginRight: "10px" }}
                        onClick={() => confirlAll()}
                        disabled={
                          isSendLoading ||
                          (!row.is_sent) ||  !recommendedDoctorItem.length
                        }
                      >
                        {isSendLoading ? (
                          <CircularProgress size={26} color="inherit" />
                        ) : (
                          "Confirm All"
                        )}
                      </SuccessBtn>

                      <SuccessBtn
                        onClick={() => setShowSharDialog(true)}
                        disabled={isSendLoading || !row.is_sent}
                      >
                        <img src={SahreIcon} />
                        Share
                      </SuccessBtn>
                    </Box>
                  }
                </Box>

                <ShareCardBox>
                  {!isAutoPopulateLoading ? (
                    <>
                      {selectedDoctorItem?.length ||
                        recommendedDoctorItem?.length ||
                        existingRecipioents?.length ? (
                        <>

                          {/* {!isRecipientsLoading ? <> */}
                          {existingRecipioents.length ? existingRecipioents.map((doctorItem: any) => {
                            return (
                              <PatentsCardRecommended>
                                <CloseIcon>
                                  <img
                                    src={CloseIcons}
                                    onClick={() => {
                                      unLink(doctorItem.grantee_id, "doctor");
                                    }}
                                  />
                                </CloseIcon>
                                <PatentsCardShareText>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"start"}
                                    sx={{ gap: "16px", p: 2 }}
                                  >
                                    <PasentImage>
                                      <img src={UserImage} />
                                    </PasentImage>
                                    <Box>
                                      <UserName> {doctorItem.name}</UserName>

                                      <UserPhone>
                                        {doctorItem?.phone ||
                                          doctorItem?.phone_number}
                                      </UserPhone>
                                      <PatientData>
                                        {doctorItem.email}
                                      </PatientData>
                                      {/* <Typography
                                        variant="body1"
                                        gutterBottom
                                        sx={{ pt: "2", color: "#808080" }}
                                      >
                                        Female, 23 Years
                                      </Typography> */}
                                    </Box>
                                  </Box>
                                </PatentsCardShareText>

                                <Box display={"flex"}>
                                  {/* <RecommendedBtn>Recommended</RecommendedBtn> */}
                                  <Reshare

                                    onClick={() => reShareModal(doctorItem, "doctor", sharingStatus)}
                                  >
                                    {confirmLoading[
                                      doctorItem?.grantee_id

                                    ] ? (
                                      <CircularProgress
                                        size={18}
                                        color="inherit"
                                      />
                                    ) : (
                                      <>
                                        Re-share <img src={ReshareIcon} />
                                      </>
                                    )}
                                  </Reshare>
                                </Box>
                              </PatentsCardRecommended>
                            );
                          }) : null}
                          {/* </>:<div style={{ marginTop: "`10px" }}>
                          <SimpleLoader height="100%" size={30} />
                        </div>} */}

                          {recommendedDoctorItem.length ? recommendedDoctorItem.map((doctorItem: any, index: any) => {
                            return (
                              <PatentsCardRecommended>
                                <CloseIcon>
                                  <img
                                    src={CloseIcons}
                                    onClick={() => {
                                      const recommendedDoctorItems = [
                                        ...recommendedDoctorItem,
                                      ];

                                      if (index !== -1) {
                                        recommendedDoctorItems.splice(index, 1);
                                        setRecommendedDoctorItem(
                                          recommendedDoctorItems
                                        );
                                      }
                                    }}
                                  />
                                </CloseIcon>
                                <PatentsCardRecommendedText>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"start"}
                                    sx={{ gap: "16px", p: 2 }}
                                  >
                                    <PasentImage>
                                      <img src={UserImage} />
                                    </PasentImage>
                                    <Box>
                                      <UserName>{doctorItem.name}</UserName>

                                      <UserPhone>{doctorItem.phone}</UserPhone>
                                      <PatientData>
                                        {doctorItem.email}
                                      </PatientData>

                                    </Box>
                                  </Box>
                                </PatentsCardRecommendedText>

                                <Box display={"flex"}>
                                  <RecommendedBtn>Recommended</RecommendedBtn>
                                  <ConfirmBtn
                                    onClick={() =>
                                      onConfirm(doctorItem, "doctor", index)
                                    }
                                    disabled={
                                      isSendLoading ||
                                      (!row.is_sent) ||
                                      confirmLoading[
                                      doctorItem?.id
                                      ]
                                    }
                                  >
                                    {confirmLoading[
                                      doctorItem?.id
                                    ] ? (
                                      <CircularProgress
                                        size={18}
                                        color="inherit"
                                      />
                                    ) : (
                                      <>
                                        Confirm <img src={ConfirmIcon} />
                                      </>
                                    )}
                                  </ConfirmBtn>
                                </Box>
                              </PatentsCardRecommended>
                            );
                          }) : null}
                          
                          {selectedDoctorItem.length ? selectedDoctorItem.map(
                            (doctor: any, index: number) => {
                              return <PatentsCardRecommended >
                                {!isSendLoading && <CloseIcon>
                                  <img src={CloseIcons} onClick={() => {
                                    const docId = doctor?.patient_fhir_id || doctor?.doctor_id
                                    const selectedDoctorItems = [...selectedDoctorItem]
                                    // const index = selectedDoctorItems.findIndex((el:any) => {
                                    if (index !== -1) {
                                      selectedDoctorItems.splice(
                                        index,
                                        1
                                      );
                                      setSelectedDoctorItem(
                                        selectedDoctorItems
                                      );
                                    }
                                  }}
                                  />
                                </CloseIcon>
                                }
                                <PatentsCardRecommendedText>
                                  <Box
                                    display={"flex"}
                                    justifyContent={"start"}
                                    sx={{ gap: "16px", p: 2 }}
                                  >
                                    <PasentImage>
                                      <img src={UserImage} />
                                    </PasentImage>
                                    <Box>
                                      <Typography
                                        variant="inherit"
                                        gutterBottom
                                        sx={{
                                          color: "#0099CC"

                                        }}
                                      ></Typography>

                                      <Box>
                                        <UserName
                                        >
                                          {doctor.name}
                                        </UserName>
                                        <Typography
                                          variant="body1"
                                          gutterBottom
                                        >
                                          {doctor?.phone || doctor?.phone_number || doctor?.value}
                                        </Typography>
                                        <PatientData>
                                          {" "}
                                          {doctor.email}
                                        </PatientData>
                                      </Box>
                                    </Box>
                                  </Box>
                                </PatentsCardRecommendedText>
                                <Box display={"flex"}>
                                  <RecommendedBtn>
                                    {sharingStatus == "AVAILABLE" ? "Doctor will be notified." : "Doctor will not be notified."}
                                  </RecommendedBtn>
                                  <ConfirmBtn
                                    onClick={() =>
                                      onConfirm(doctor, "doctor", index)
                                    }
                                    disabled={
                                      isSendLoading ||
                                      !attachedPatient?.id ||
                                      confirmLoading[
                                      doctor?.patient_fhir_id ||
                                      doctor?.doctor_id
                                      ]
                                    }
                                  >
                                    {confirmLoading[
                                      doctor?.patient_fhir_id ||
                                      doctor?.doctor_id
                                    ] ? (
                                      <CircularProgress
                                        size={18}
                                        color="inherit"
                                      />
                                    ) : (
                                      <>
                                        Confirm <img src={ConfirmIcon} />
                                      </>
                                    )}
                                  </ConfirmBtn>
                                </Box>
                              </PatentsCardRecommended>
                            }) : null}
                        </>
                      ) : (
                        <p>Click on share button to add doctors and others</p>
                      )}
                    </>
                  ) : (
                    <div style={{ marginTop: "10px" }}>
                      <PatentsCardRecommended>
                        <CloseIcon>
                          <img
                            src={CloseIcons}
                            onClick={() =>
                              autoPopulateSourceToken.cancel(
                                "Operation canceled by the user."
                              )
                            }
                          />
                        </CloseIcon>
                        <PatentsCardRecommendedText>
                          <Box
                            display={"flex"}
                            justifyContent={"center"}
                            alignItems={"center"}
                            sx={{ gap: "16px", p: 2, height: "100px" }}
                          >
                            <Box >
                              <SimpleLoader height="14" text={"Populating Doctor Data"} />
                            </Box>
                          </Box>
                        </PatentsCardRecommendedText>
                        <Box display={"flex"}>
                          <RecommendedBtn
                            style={{
                              width: "100%",
                              height: "20px",
                              borderRadius: "0px 0px 12px 12px",
                            }}
                          ></RecommendedBtn>
                        </Box>
                      </PatentsCardRecommended>
                    </div>
                  )}
                </ShareCardBox>
              </Box>

              <Box
                display={"flex"}
                justifyContent={"start"}
                sx={{ marginBottom: "20px", gap: "16px" }}
              >
                {/* series section */}

                <Box
                  sx={{
                    // minWidth: "800px",
                    background: "#fff",
                    borderRadius: "8px",
                    width: "65%",
                  }}
                  padding={"20px"}
                >
                  <Box sx={{ mb: 2 }}>
                    <Typography
                      variant="inherit"
                      gutterBottom
                      sx={{ color: "#0099CC" }}
                    >
                      Series
                    </Typography>
                  </Box>
                  <CustomSeriesTable
                    index={index}
                    onSelectRowSeries={onSelectRowSeries}
                    seletAllDelete={seletAllDelete}
                    expandedSeriesData={expandedSeriesData[row.study_id]}
                    deleteMultipleSeries={deleteMultipleSeries}
                    checkAllSeries={checkAllSeries}
                    fetchViewerUrls={fetchViewerUrls}
                  />
                </Box>

                {/* reports section */}

                <Box
                  sx={{
                    background: "#fff",
                    borderRadius: "8px",
                    width: "35%",
                  }}
                  padding={"20px"}
                >
                  <Box
                    display={"flex"}
                    justifyContent={"space-between"}
                    paddingBottom={"28px"}
                    gap={1}
                  >
                    <Typography
                      variant="inherit"
                      gutterBottom
                      sx={{ color: "#0099CC" }}
                    >
                      Reports
                    </Typography>
                    <SuccessBtn
                      onClick={() => setShowReportDialog(true)}
                      disabled={isSendLoading || !row.is_sent}
                    >
                      <AttachIcon /> Attach Report
                    </SuccessBtn>
                  </Box>
                  <Box>
                    {row.is_sent ?
                      <>
                        {!isReportsLoading ?
                          <Box>
                            {!mediaArray?.length ? (
                              <p>Click on attach report button to add</p>
                            ) : (
                              <ReportDetailsCard
                                mediaArray={mediaArray}
                                setMediaArray={setMediaArray}
                                unlink={unLink}
                                isSent={row.is_sent}
                                recordStatusChange={recordStatusChange}
                              />
                            )}
                          </Box> :
                          <div style={{ marginTop: "59px" }}>
                            <SimpleLoader height="100%" size={30} />
                          </div>
                        }
                      </> : <p>Click on attach report button to add</p>}
                    <Box></Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>

      {
        isShowSharDialog ? (
          <ShareDoctorDialog
            open={isShowSharDialog}
            // onDelete={() => onDeleteScans()}
            isLoading={false}
            testId={"test"}
            title={"Add for sharing"}
            isAutoPopulate={isAutoPopulate}
            autoPopulateData={autoPopulateData}
            setIsautoPopulate={setIsautoPopulate}
            setautoPopulateData={setautoPopulateData}
            setSelectedPatient={setSelectedPatient}
            setSelectedDoctor={setSelectedDoctor}
            setSelectedDoctorItem={setSelectedDoctorItem}
            selectedDoctorItem={selectedDoctorItem}
            setConfirmLoading={setConfirmLoading}
            confirlAll={confirlAll}
            isSendLoading={isSendLoading}

            // discription={"This action cannot be undone once deleted."}
            closeHandler={() => {
              setShowSharDialog(false);
            }}
          />
        ) : (
          ""
        )
      }

      {
        isShowReportDialog ? (
          <ReportAttachDialog
            open={isShowReportDialog}
            testId={"test"}
            title={"Add for sharing"}
            closeHandler={() => {
              setShowReportDialog(false);
            }}
            setMediaArray={setMediaArray}
            attchReports={attchReports}
            mediaArrayData={mediaArray}
            isAttachLoading={isAttachLoading}
            scansData={row}
          />
        ) : (
          ""
        )
      }

      {
        isShowDeleteAttachment ? (
          <ScanDeleteSure
            open={DeleteAttachmentProps}
            isLoading={isDeleteAttachmentLoading}
            closeHandler={() => {
              setShowDeleteAttachment(false);
              setDeleteAttachmentProps(null);
              setAllowDirectLink(false)

            }}
            {...DeleteAttachmentProps}
            isDirectLinkEnabled={isDirectLinkEnabled}
            isAllowDirectLink={isAllowDirectLink}
            handleDirectLinkChangeForScan={()=>setAllowDirectLink(!isAllowDirectLink)}
            onResharePatient={(item:any,type:string,isDirectLink:boolean)=> onReShare(item,type,isDirectLink)}
          />
        ) : (
          ""
        )
      }

      {
        isPDf && (
          <div style={{ marginTop: "400px" }}>
            <PatientReportPdf
              ref={studyDownloadRef}
              data={downloadingData}
              setPdf={setIsPdf}
              downloadingImageData={downloadingImageData}
              scansAuthToken={scansAuthToken}
            />
          </div>
        )
      }

      {
        isShowDownload ? (
          <ScanDeleteSure
            open={isShowDownload}
            isLoaderPopup={true}
            isLoading={isDownLoading}
            testId={"test"}
            title={""}
            discription={""}
            closeHandler={() => {
              setShowDownload(false);
            }}
          />
        ) : (
          ""
        )
      }
    </React.Fragment >
  );
}

interface IExpandedItems {
  [key: string]: boolean;
}


interface Config {
  id: number;
  config_name: string;
  config_display_name: string;
  config_value: string;
  config_type: string;
}

export default function NewScansUi() {
  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<keyof Data>("study_timestamp");
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [activeButton, setActiveButton] = useState<
    "notReviewed" | "reviewedAndSent"
  >("notReviewed");
  const [studyData, setStudyData] = useState<any>([]);
  const [cookies, setCookie, removeCookie] = useCookies();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isScansLoading, setIsScansLoading] = useState<boolean>(true);
  const [isSeriesLoading, setIsSeriesLoading] = useState<boolean>(true);

  const [isLoadingDiacom, setIsLoadingDiacom] = useState<boolean>(false);
  const [scansData, setScansData] = useState<any>([]);
  const [modalityList, setModalityList] = useState<any>([]);
  const [scansFilteredData, setScansFilteredData] = useState<any>([]);
  const [filterValue, setFilterValue] = useState<any>({
    name: "",
    patient_id: "",
    accession_number: "",
    patientAttached: "",
    sharingStatus:"",
    modality: [],
    studyDate: "",
    accessNumber: "",
  });

  const navigate = useNavigate();
  const [activeList, setActiveList] = useState<"seriesList" | "studyList">(
    "studyList"
  );
  const [expandedItems, setExpandedItems] = useState<IExpandedItems>({});
  const [expandedSeriesData, setExpandedSeriesData] = useState<any>({});

  const [isShowDeleteScan, setShowDeleteScan] = useState(false);
  const [seletAllDelete, setSelectAllDelete] = useState<any>({});
  const [slectedStudyId, setSlectedStudyId] = useState<any>("");
  const [slectedSeriesId, setSlectedSeriesId] = useState<any>("");
  const [slectedRowId, setSlectedRowId] = useState<any>(null);
  const [isSentStudy, setIsSentStudy] = useState<boolean>(false);
  const [isPatientDelete, setIsPatientDelete] = useState<boolean>(false);

  const [isStudyDelete, setIsStudyDelete] = useState<boolean>(false);
  const [isSeriesDelete, setIsSeriesDelete] = useState<boolean>(false);
  const [isDeleteLoading, setIsDeleteLoading] = useState<boolean>(false);

  const [selectedStoreId, setSelectedStoreId] = useState<any>("");

  const [selectedDoctorItem, setSelectedDoctorItem] = useState<any>([]);
  const [recommendedDoctorItem, setRecommendedDoctorItem] = useState<any>([]);
  const [isAutosendEnabled, setIsAutosendEnabled] = useState<boolean>(false)
  const [configsList, setConfigsList] = useState<Config[]>([]);
  const [isDirectLinkEnabled, setDirectLinkEnabled] = useState<boolean>(false)
  const [isAllowDirectLink, setAllowDirectLink] = useState<boolean>(false);



  const [viewerMenu, setViewerMenu] = useState<any>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setViewerMenu(event.currentTarget);
  };

  const onUnload = (event: any) => {
    localStorage.removeItem("isNewAlreadyLoaded");
    localStorage.removeItem("scansNewData");
    localStorage.removeItem("reviewedScansNewData");
  };
  useEffect(() => {
    window.addEventListener("beforeunload", onUnload);
  }, []);


  useEffect(() => {
    const isNewAlreadyLoaded = localStorage.getItem("isNewAlreadyLoaded");

    if (
      isNewAlreadyLoaded == null ||
      (isNewAlreadyLoaded !== null && !JSON.parse(isNewAlreadyLoaded))
    ) {
      setIsLoading(true);
      Promise.all([
        fetchNotReviewedData(),
        fetchModalityListData()

        // fetchReviewedData()
      ]).finally(() => {
        setIsLoading(false);
        setIsScansLoading(false);
        localStorage.setItem("isNewAlreadyLoaded", JSON.stringify(true));
      });
    } else {
      const scansLocalData: any = localStorage.getItem("scansNewData") || [];
      const reviewedScansLoaclData: any =
        localStorage.getItem("reviewedScansNewData") || [];
      fetchModalityListData()

      setIsLoading(false);
      setIsScansLoading(false);
      setScansData(
        scansLocalData !== null && scansLocalData.length && scansLocalData
          ? JSON.parse(scansLocalData)
          : []
      );
    }
  }, []);

  useEffect(() => {
    getAllConfigs();
  }, []);



  const handleDirectLinkChangeForScan = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const isChecked = event.target.checked;
    setAllowDirectLink(isChecked);
  };

  const getAllConfigs = () => {
    const token = cookies["access_token"];
    const url = `${API_ENDPOINT.CONFIGURATION_LIST}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    axios
      .get(url, config)
      .then((response) => {
        if (response?.data?.status === "success") {
          const configs = response?.data?.data;
          setConfigsList(configs);

          // Check for the autosend config
          const autosendConfig = configs.find(
            (config: Config) => config.config_name === "autosend"
          );

          if (autosendConfig) {
            // Convert the config_value to boolean and set the state
            setIsAutosendEnabled(autosendConfig.config_value === "true");
          }

          // Check for the direct link config
          const directLinkConfig = configs.find(
            (config: Config) => config.config_name === "direct_link"
          );

          if (directLinkConfig) {
            // Convert the config_value to boolean and set the state
            setDirectLinkEnabled(directLinkConfig.config_value === "true");
          }
        }
      })
      .catch((error) => {
        console.log(error);
      })
  };


  const fetchModalityListData = async () => {
    const token = cookies["access_token"];
    const countryData = cookies["countryData"];
    const url = `${API_URL}/unsent/modalities`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);

      if (response?.data?.status === "success") {
        const result = response?.data?.result;
        setModalityList(result)
        return result;
      }
    } catch (error) {
      console.error(error);
    }
  };



  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {

    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDense(event.target.checked);
  };

  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - scansData.length) : 0;

  useEffect(() => {
    const isFiltered = (filterValue.name || filterValue.patient_id || filterValue.accession_number || filterValue.patientAttached || filterValue?.modality?.length || filterValue.studyDate || filterValue.sharingStatus) ? true : false

    const visibleRows = stableSort(
      (isFiltered || scansFilteredData?.length) ? scansFilteredData : scansData,
      getComparator(order, orderBy)
    ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    setStudyData(visibleRows);
  }, [order, orderBy, page, rowsPerPage, scansData, scansFilteredData]);

  const [userDob, setUserDob] = useState("");
  const handleDobChange = (newValue: any) => {
    setUserDob(newValue);
  };

  const fetchNotReviewedData = async () => {
    const token = cookies["access_token"];
    const countryData = cookies["countryData"];
    const url = `${API_URL}/unsent/study?country_code=${countryData.code}&sent=false&size=200`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);

      if (response?.data?.status === "success") {
        const result = response?.data?.result;

        // const scansArray = Object.values(result);

        setScansData(result);
        localStorage.setItem("scansNewData", JSON.stringify(result));
        return result;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fetchSeriesDataById = async (study_id: any = "") => {
    // setIsSeriesLoading(true)
    const token = cookies["access_token"];
    const countryData = cookies["countryData"];

    const url = `${API_URL}/unsent/study/${study_id}/series?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.get(url, config);
      if (response?.data?.status === "success") {
        setTimeout(() => {
          setExpandedSeriesData((prevState: any) => ({
            ...prevState,
            [study_id]: response?.data?.result || [],
          }));
        }, 2000);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const toggleExpanded = (study_id: string) => {
    if (!expandedItems[study_id]) {
      fetchSeriesDataById(study_id);
    }
    setExpandedItems((prevState) => ({
      // ...prevState,
      [study_id]: !prevState[study_id],
    }));
  };

  const deleteStudy = (parentscanId: any, rowNumber: any, storeId?: string, row?: any, isPatientDelete?: boolean) => {
    setSlectedRowId(rowNumber);
    setSlectedStudyId(parentscanId);
    setSelectedStoreId(storeId);
    setIsStudyDelete(true);
    setShowDeleteScan(true);
    setIsSentStudy(row.is_sent)
    setIsPatientDelete(isPatientDelete ? true : false);

  };

  const onDeleteStudy = () => {
    callDeleteScanStudysApi(slectedStudyId);
  };
  const onDeleteSeries = () => {
    callDeleteScanseriesApi(slectedStudyId, slectedSeriesId);
  };

  const onDeleteScans = async () => {
    if (isStudyDelete) {
      setFilterValue({
        name: "",
        patient_id: "",
        accession_number: "",
        patientAttached: "",
        sharingStatus:"",
        modality: [],
        studyDate: "",
        accessNumber: "",
      })
      onDeleteStudy();
    } else if (isSeriesDelete) {
      onDeleteSeries();
    } else {
      let selectedDataIds = seletAllDelete[slectedRowId];
      let study_id = slectedStudyId; //seletAllDelete[rowNumber][0].study_id
      if (selectedDataIds.length) {
        let deleteAll = selectedDataIds.map((id: string) => {
          return callDeleteScanseriesApi(study_id, id);
        });
      }
    }
  };

  const callDeleteScanseriesApi = async (study_id: any, series_id: any) => {
    setIsDeleteLoading(true);
    const token = cookies["access_token"];
    const countryData = cookies["countryData"];
    const url = `${API_URL}/unsent/store/${selectedStoreId}/study/${study_id}/series/${series_id}?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(url, config);
      if (response?.data?.status === "success") {
        setIsSeriesDelete(false);
        setIsDeleteLoading(false);
        fetchSeriesDataById(study_id);
        fetchNotReviewedData();
        setSelectAllDelete({});
        setShowDeleteScan(false);
      }
    } catch (error) {
      setIsSeriesDelete(false);
      setIsDeleteLoading(false);
      setShowDeleteScan(false);
      console.error(error);
    }
  };

  const callDeleteScanStudysApi = async (study_id: any) => {
    setIsDeleteLoading(true);
    const token = cookies["access_token"];
    const countryData = cookies["countryData"];
    const url = `${API_URL}/unsent/store/${selectedStoreId}/study/${study_id}?country_code=${countryData.code}`;
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    try {
      const response = await axios.delete(url, config);
      if (response?.data?.status === "success") {
        setTimeout(() => {
          fetchNotReviewedData().then((data) => {
            setIsStudyDelete(false);
            setIsDeleteLoading(false);
            setShowDeleteScan(false);
          });

          setSlectedRowId("");
          setSlectedStudyId("");
          setSelectAllDelete({});
        }, 0);

      }
    } catch (error) {
      setIsStudyDelete(false);
      setShowDeleteScan(false);
      setIsDeleteLoading(false);
      console.error(error);
    }
  };

  const onSelectRowSeries = (rowNumber: number, seletedSeries: any) => {
    let seletAllDeleteCopy = { ...seletAllDelete };
    if (
      seletAllDeleteCopy[`${rowNumber}`] &&
      Array.isArray(seletAllDeleteCopy[`${rowNumber}`])
    ) {
      if (
        seletAllDeleteCopy[`${rowNumber}`].includes(seletedSeries.series_id)
      ) {
        let index = seletAllDeleteCopy[`${rowNumber}`].findIndex(
          (x: any) => x == seletedSeries.series_id
        );
        seletAllDeleteCopy[`${rowNumber}`].splice(index, 1);
      } else {
        seletAllDeleteCopy[`${rowNumber}`].push(seletedSeries.series_id);
      }
    } else {
      seletAllDeleteCopy[`${rowNumber}`] = [];
      seletAllDeleteCopy[`${rowNumber}`].push(seletedSeries.series_id);
    }
    setSelectAllDelete(seletAllDeleteCopy);
  };

  const checkAllSeries = (rowNumber: number, row: any) => {
    let seletAllDeleteCopy = { ...seletAllDelete };
    if (
      seletAllDelete[rowNumber] &&
      seletAllDelete[rowNumber].length === row.length
    ) {
      seletAllDeleteCopy[`${rowNumber}`] = [];
    } else {
      seletAllDeleteCopy[`${rowNumber}`] =
        row.length > 0 ? row.map((item: any) => item.series_id) : [];
    }
    setSelectAllDelete(seletAllDeleteCopy);
  };

  const deleteMultipleSeries = (
    parentscanId: any,
    rowNumber: any,
    storeId?: string
  ) => {
    setSlectedRowId(rowNumber);
    setSlectedStudyId(parentscanId);
    setSelectedStoreId(storeId);
    setIsSeriesDelete(false);
    setIsStudyDelete(false);
    setShowDeleteScan(true);
  };

  const openInNewTab = (url: any) => {
    if (url) {
      const newWindow = window.open(url, "_blank", "noopener,noreferrer");
      if (newWindow) newWindow.opener = null;
    }
  };

  const onRefreshData = () => {

    setIsLoading(true);
    Promise.all([
      fetchNotReviewedData(),
    ]).finally(() => {
      setFilterValue({
        name: "",
        patient_id: "",
        accession_number: "",
        patientAttached: "",
        sharingStatus:"",
        modality: [],
        studyDate: "",
        accessNumber: "",
      });
      setScansFilteredData([]);
      setExpandedItems({});
      setIsLoading(false);
      localStorage.setItem("isNewAlreadyLoaded", JSON.stringify(true))
    });

  }

  const fetchViewerUrls = async (
    rowData: any,
    type: string,
    listType: string
  ) => {
    const token = cookies["access_token"];
    const countryData = cookies["countryData"];
    const url = `${API_URL}/unsent/study?country_code=${countryData.code}`;
    const studyUrl = `${API_URL}/unsent/study/${rowData?.study_id}/viewer?country_code=${countryData.code}&store_id=${rowData?.store_id}`;
    const seriesUrl = `${API_URL}/unsent/study/${rowData?.study_id}/series/${rowData?.study_id}/viewer?country_code=${countryData.code}&store_id=${rowData?.store_id}`;
    const medreamViewerUrl = `${API_URL}/list_data/viewer`
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    if(type == "medDream"){
    try {
      const response = await axios.post(
        medreamViewerUrl,
        {
          "store_id":rowData?.store_id,
              "study_id": rowData?.study_id
          },
        config
      );

      if (response?.data?.status === "success") {
        const result = response?.data?.data;
          openInNewTab(result["MedDream Viewer"]);
      }
    } catch (error) {
      console.error(error);
    }
  }else{
    try {
      const response = await axios.get(
        listType == "series" ? seriesUrl : studyUrl,
        config
      );

      if (response?.data?.status === "success") {
        const result = response?.data?.result;
        if (type == "basic") {
          openInNewTab(result?.basic_viewer);
        } else {
          openInNewTab(result?.tmtv);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }
  };

  const onFilterChange = (e: any, type: string) => {
    if (type == "studyDate") {
      setFilterValue({ ...filterValue, [type]: e });
    } else if (type == "modality") {
      const {
        target: { value },
      } = e;
      setFilterValue({
        ...filterValue,
        [type]: typeof value === "string" ? value.split(",") : value,
      });
    } else {
      setFilterValue({ ...filterValue, [type]: e?.target?.value });
    }
  };

  useEffect(() => {
    if (
      filterValue.patient_id ||
      filterValue.name ||
      filterValue.accession_number ||
      filterValue.patientAttached ||
      filterValue.sharingStatus ||
      filterValue.modality.length > 0 ||
      filterValue.studyDate
    ) {
      let data = [];
      data = scansData.filter((x: any) => {
        let filterQuery = x.patient_name
          .toLowerCase()
          .includes(filterValue.name.toLowerCase());
        // if(filterValue.name){
        //   filterQuery =  x.patient_name.toLowerCase().includes(filterValue.name.toLowerCase())
        // }
        if (filterValue.patientAttached) {
          filterQuery =
            filterQuery && x.is_sent.toString() == filterValue.patientAttached;
        }

        if (filterValue.sharingStatus) {

        if(filterValue.sharingStatus == 'AVAILABLE') {
          filterQuery =
            filterQuery && (x.fhir_resource_status == filterValue.sharingStatus || x.fhir_resource_status == null);
        }else{
          filterQuery =
          filterQuery && x.fhir_resource_status == filterValue.sharingStatus;
        }
          
        }
        if (filterValue.patient_id) {
          filterQuery =
            filterQuery && x.patient_id && x.patient_id.toString().toLowerCase()
              .includes(filterValue.patient_id.toLowerCase());
        }
        if (filterValue.accession_number) {
          filterQuery =
            filterQuery && x.accession_number && x.accession_number.toString().toLowerCase()
              .includes(filterValue.accession_number.toLowerCase());;
        }
        if (filterValue.modality.length) {
          let rowModality = x.modality_list.split(",")
          const commonElements: any = filterValue.modality.filter((element: any) => rowModality.includes(element))
          filterQuery = filterQuery && commonElements.length > 0;
        }

        if (filterValue.studyDate) {
          let filterData = formattedDate(filterValue.studyDate);
          let studyDate = formattedDate(x.study_timestamp);
          filterQuery = filterQuery && studyDate == filterData;
        }
        return filterQuery;
      });


      if (data) {
        setScansFilteredData(data);
      } else {
        setScansFilteredData([]);
      }
    } else {
      setScansFilteredData([]);
    }
  }, [filterValue]);

  return (
    <Container>
      <div style={{ marginBottom: '10px', fontSize: '18px', textAlign: "right", width: "100%", fontWeight: 'bold', color: '#2faed7' }}>
        AutoSend:
        <span
          style={{
            fontSize: '16px',
            color: isAutosendEnabled ? '	#32CD32' : '#FF0000',  // Green text for ON, red text for OFF
            fontWeight: 'lighter',
            paddingLeft: '5px',
          }}
        >
          {isAutosendEnabled ? "ON" : "OFF"}
        </span>
      </div>
      <HeaderRow>
        <ReviewButtonContainer>
          <AddTempWrapper>
            <ButtonBase
              active={activeButton === "notReviewed"}
              onClick={() => {
                setActiveButton("notReviewed");
              }}
            >
              Scans
            </ButtonBase>
          </AddTempWrapper>
          <AddTempWrapper>
            {/* <ButtonBase
              active={activeButton === "reviewedAndSent"}
              onClick={() => {
                setActiveButton("reviewedAndSent");
                // setExpandedInstencesstdyList({});
                // setExpandedInstencesstdyListData({});
                // setSeletedInstences({});
              }}
            >
              Advanced Search
            </ButtonBase> */}
          </AddTempWrapper>

        </ReviewButtonContainer>
        <div style={{ display: 'flex' }}>
          <AddTempWrapper>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <ClearsearchBtn onClick={onRefreshData} disabled={isLoading} style={{ minWidth: "120px", textAlign: 'center', justifyContent: 'center' }}>
                {isLoading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  "Refresh"
                )}
              </ClearsearchBtn>
            </div>
          </AddTempWrapper>
        </div>
      </HeaderRow>

      <Box
        sx={{
          width: "100%",
          paddingTop: "20px",
          background: "#fff",
          minHeight: "70vh",
        }}
      >
        {!isLoading ? (
          <>

            {scansData && scansData.length ? (
              <Paper
                sx={{ width: "98%", mb: 2, boxShadow: "none", mx: "auto" }}
              >
                {/* <EnhancedTableToolbar numSelected={selected.length} /> */}
                <TableContainer>
                  <Table
                    sx={{ minWidth: 750 }}
                    aria-labelledby="tableTitle"
                    aria-label="collapsible table"
                    size={dense ? "small" : "medium"}
                    className="scansTable"
                  >
                    <EnhancedTableHead
                      numSelected={selected.length}
                      order={order}
                      orderBy={orderBy}
                      // onSelectAllClick={handleSelectAllClick}
                      onRequestSort={handleRequestSort}
                      rowCount={scansData.length}
                    />
                    <TableRow className="searchHeader">
                      <TableCell
                        component="th"
                        scope="row"
                        className="search-table"
                      >
                        <TextField
                          id="input-with-icon-textfield"
                          value={filterValue.name}
                          onChange={(e: any) => onFilterChange(e, "name")}
                          placeholder="Search"
                          sx={{ minWidth: "140px" }}

                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0209 11.0781L14.8762 13.9334L13.9336 14.8761L11.0789 12.0207C9.88079 12.9779 8.36165 13.44 6.83348 13.3121C5.30531 13.1842 3.88411 12.4761 2.86175 11.333C1.8394 10.19 1.29351 8.69897 1.33618 7.16605C1.37885 5.63313 2.00686 4.17473 3.09122 3.09037C4.17557 2.00601 5.63397 1.37801 7.16689 1.33533C8.69981 1.29266 10.1909 1.83856 11.3339 2.86091C12.4769 3.88326 13.1851 5.30446 13.313 6.83263C13.4408 8.36081 12.9781 9.87994 12.0209 11.0781ZM10.6836 10.5834C11.5411 9.69945 12.0145 8.51237 12.0005 7.2809C11.9865 6.04943 11.4863 4.87341 10.6089 4.0092C9.73144 3.14498 8.54798 2.66264 7.31644 2.6673C6.08489 2.67197 4.90512 3.16326 4.03428 4.0341C3.16344 4.90494 2.67215 6.08471 2.66748 7.31626C2.66282 8.5478 3.14516 9.73126 4.00938 10.6087C4.87359 11.4861 6.04961 11.9863 7.28108 12.0003C8.51255 12.0143 9.69963 11.5409 10.5836 10.6834L10.6836 10.5834Z"
                                    fill="#7ABA56"
                                  />
                                </svg>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                        />
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <TextField
                          id="input-with-icon-textfield"
                          type=""
                          sx={{ minWidth: "140px" }}

                          placeholder="Search"
                          value={filterValue.patient_id}
                          onChange={(e: any) => onFilterChange(e, "patient_id")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0209 11.0781L14.8762 13.9334L13.9336 14.8761L11.0789 12.0207C9.88079 12.9779 8.36165 13.44 6.83348 13.3121C5.30531 13.1842 3.88411 12.4761 2.86175 11.333C1.8394 10.19 1.29351 8.69897 1.33618 7.16605C1.37885 5.63313 2.00686 4.17473 3.09122 3.09037C4.17557 2.00601 5.63397 1.37801 7.16689 1.33533C8.69981 1.29266 10.1909 1.83856 11.3339 2.86091C12.4769 3.88326 13.1851 5.30446 13.313 6.83263C13.4408 8.36081 12.9781 9.87994 12.0209 11.0781ZM10.6836 10.5834C11.5411 9.69945 12.0145 8.51237 12.0005 7.2809C11.9865 6.04943 11.4863 4.87341 10.6089 4.0092C9.73144 3.14498 8.54798 2.66264 7.31644 2.6673C6.08489 2.67197 4.90512 3.16326 4.03428 4.0341C3.16344 4.90494 2.67215 6.08471 2.66748 7.31626C2.66282 8.5478 3.14516 9.73126 4.00938 10.6087C4.87359 11.4861 6.04961 11.9863 7.28108 12.0003C8.51255 12.0143 9.69963 11.5409 10.5836 10.6834L10.6836 10.5834Z"
                                    fill="#7ABA56"
                                  />
                                </svg>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                        />
                      </TableCell>  <TableCell component="th" scope="row">
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{ minWidth: "145px" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                           Sharing status
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{
                              ".MuiSelect-icon": {
                                top: "calc(50% - 2px)",
                                right: "15px",
                              },
                              ".MuiSelect-icon.MuiSelect-iconOpen": {
                                top: "calc(50% - 3px)",
                                right: "15px",
                              },
                            }}
                            IconComponent={DownArrowIcon}
                            onChange={(e: any) =>
                              onFilterChange(e, "sharingStatus")
                            }
                            // value={}
                            label={"Sharing status"}
                            value={filterValue.sharingStatus}
                          >
                            <MenuItem value={"AVAILABLE"}>Available</MenuItem>
                            <MenuItem value={"RESTRICTED"}>Restricted</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>

                      <TableCell component="th" scope="row">
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{ minWidth: "160px" }}
                        >
                          <InputLabel id="demo-simple-select-label">
                            Patient Attached
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            sx={{
                              ".MuiSelect-icon": {
                                top: "calc(50% - 2px)",
                                right: "15px",
                              },
                              ".MuiSelect-icon.MuiSelect-iconOpen": {
                                top: "calc(50% - 3px)",
                                right: "15px",
                              },
                            }}
                            IconComponent={DownArrowIcon}
                            onChange={(e: any) =>
                              onFilterChange(e, "patientAttached")
                            }
                            // value={}
                            label={"Patient Attached"}
                            value={filterValue.patientAttached}
                          >
                            <MenuItem value={"false"}>No</MenuItem>
                            <MenuItem value={"true"}>Yes</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <FormControl
                          fullWidth
                          size="small"
                          sx={{ minWidth: "110px" }}
                        >
                          <InputLabel id="demo-multiple-checkbox-label">
                            Modality
                          </InputLabel>
                          <Select
                            labelId="demo-multiple-checkbox-label"
                            id="demo-multiple-checkbox"
                            multiple
                            sx={{
                              ".MuiSelect-icon": {
                                top: "calc(50% - 2px)",
                                right: "15px",
                              },
                              ".MuiSelect-icon.MuiSelect-iconOpen": {
                                top: "calc(50% - 3px)",
                                right: "15px",
                              },
                            }}
                            label={"Modality"}
                            input={<OutlinedInput label="Modality" />}
                            displayEmpty
                            IconComponent={DownArrowIcon}
                            onChange={(e: any) => {
                              onFilterChange(e, "modality");
                            }}
                            value={filterValue.modality}
                            // inputProps={{ "aria-label": "Without label" }}
                            renderValue={(selected) => selected.join(", ")}
                            MenuProps={{ PaperProps: { sx: { maxHeight: 360 } } }}

                          >
                            <MenuItem disabled value="">
                              <span style={{ color: "grey" }}>Modality</span>
                            </MenuItem>

                            {modalityList && modalityList.length > 0 ? modalityList.map((modality: any) => {
                              return (
                                <MenuItem key={modality} value={modality}>
                                  <Checkbox
                                    checked={
                                      filterValue.modality.indexOf(modality) >
                                      -1
                                    }
                                  />
                                  <ListItemText primary={modality} />
                                </MenuItem>
                              )
                            }) : ""}

                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <div >

                          <FormControl size="small" sx={{ minWidth: "150px" }} >
                            <LocalizationProvider dateAdapter={AdapterMoment}>
                              <DatePicker
                                inputFormat="DD-MM-YYYY"
                                disableFuture
                                label=""

                                value={filterValue.studyDate || null}
                                onChange={(e: any) =>
                                  onFilterChange(e, "studyDate")
                                }
                                // onChange={handleDobChange}
                                InputProps={{
                                  placeholder: "DD-MM-YYYY",
                                }}
                                renderInput={(params) => (
                                  <TextField
                                    sx={{
                                      svg: { color: "#7BBA56" },
                                      ".MuiInputBase-root": {
                                        height: "40px",
                                      },
                                      ".MuiInputBase-input": {
                                        borderColor: "#E4E4E7",
                                      },

                                      "& .MuiOutlinedInput-notchedOutline": {
                                        borderColor: "#E4E4E7",
                                      },
                                      "&:hover > .MuiOutlinedInput-notchedOutline":
                                      {
                                        borderColor: "#E4E4E7",
                                      },
                                    }}
                                    {...params}
                                    helperText={null}
                                  />
                                )}
                              />
                            </LocalizationProvider>
                          </FormControl>
                        </div>
                      </TableCell>
                      <TableCell component="th" scope="row">
                        <TextField
                          id="input-with-icon-textfield"
                          type=""
                          sx={{ minWidth: "140px" }}

                          placeholder="Search"
                          value={filterValue.accession_number}
                          onChange={(e: any) => onFilterChange(e, "accession_number")}
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start">
                                <svg
                                  width="16"
                                  height="16"
                                  viewBox="0 0 16 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M12.0209 11.0781L14.8762 13.9334L13.9336 14.8761L11.0789 12.0207C9.88079 12.9779 8.36165 13.44 6.83348 13.3121C5.30531 13.1842 3.88411 12.4761 2.86175 11.333C1.8394 10.19 1.29351 8.69897 1.33618 7.16605C1.37885 5.63313 2.00686 4.17473 3.09122 3.09037C4.17557 2.00601 5.63397 1.37801 7.16689 1.33533C8.69981 1.29266 10.1909 1.83856 11.3339 2.86091C12.4769 3.88326 13.1851 5.30446 13.313 6.83263C13.4408 8.36081 12.9781 9.87994 12.0209 11.0781ZM10.6836 10.5834C11.5411 9.69945 12.0145 8.51237 12.0005 7.2809C11.9865 6.04943 11.4863 4.87341 10.6089 4.0092C9.73144 3.14498 8.54798 2.66264 7.31644 2.6673C6.08489 2.67197 4.90512 3.16326 4.03428 4.0341C3.16344 4.90494 2.67215 6.08471 2.66748 7.31626C2.66282 8.5478 3.14516 9.73126 4.00938 10.6087C4.87359 11.4861 6.04961 11.9863 7.28108 12.0003C8.51255 12.0143 9.69963 11.5409 10.5836 10.6834L10.6836 10.5834Z"
                                    fill="#7ABA56"
                                  />
                                </svg>
                              </InputAdornment>
                            ),
                          }}
                          size="small"
                        />
                      </TableCell>
                      <TableCell component="th" scope="row"></TableCell>
                      {/* <TableCell component="th" scope="row"></TableCell> */}
                      <TableCell
                        component="th"
                        scope="row"
                        align="right"
                        sx={{ minWidth: "215px" }}
                      >
                        <ClearsearchBtn
                          onClick={() => {
                            setFilterValue({
                              name: "",
                              patient_id: "",
                              accession_number: "",
                              patientAttached: "",
                              sharingStatus:"",
                              modality: [],
                              studyDate: "",
                              accessNumber: "",
                            });
                            setScansFilteredData([]);
                          }}
                        >
                          {" "}
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M8.00001 14.6663C4.31811 14.6663 1.33334 11.6815 1.33334 7.99967C1.33334 4.31777 4.31811 1.33301 8.00001 1.33301C11.6819 1.33301 14.6667 4.31777 14.6667 7.99967C14.6667 11.6815 11.6819 14.6663 8.00001 14.6663ZM8.00001 13.333C10.9455 13.333 13.3333 10.9452 13.3333 7.99967C13.3333 5.05415 10.9455 2.66634 8.00001 2.66634C5.05449 2.66634 2.66668 5.05415 2.66668 7.99967C2.66668 10.9452 5.05449 13.333 8.00001 13.333ZM8.00001 7.05687L9.88561 5.17125L10.8284 6.11405L8.94281 7.99967L10.8284 9.88527L9.88561 10.8281L8.00001 8.94247L6.11439 10.8281L5.17158 9.88527L7.05721 7.99967L5.17158 6.11405L6.11439 5.17125L8.00001 7.05687Z"
                              fill="white"
                            />
                          </svg>
                          Clear search
                        </ClearsearchBtn>
                      </TableCell>
                    </TableRow>
                    <TableBody>
                      {studyData?.length ? (
                        studyData.map((row: any, index: number) => {
                          const isItemSelected = isSelected(row.id);
                          const labelId = `enhanced-table-checkbox-${index}`;

                          return (
                            <Row
                              key={index}
                              index={index}
                              row={row}
                              toggleExpanded={toggleExpanded}
                              expandedSeriesData={expandedSeriesData}
                              expandedItems={expandedItems}
                              onDelete={deleteStudy}
                              checkAllSeries={checkAllSeries}
                              onSelectRowSeries={onSelectRowSeries}
                              seletAllDelete={seletAllDelete}
                              deleteMultipleSeries={deleteMultipleSeries}
                              fetchViewerUrls={fetchViewerUrls}
                              fetchNotReviewedData={fetchNotReviewedData}
                              recommendedDoctorItem={recommendedDoctorItem}
                              setRecommendedDoctorItem={
                                setRecommendedDoctorItem
                              }
                              selectedDoctorItem={selectedDoctorItem}
                              setSelectedDoctorItem={setSelectedDoctorItem}
                              setFilterValue={setFilterValue}
                              handleDirectLinkChangeForScan={handleDirectLinkChangeForScan}
                              isAllowDirectLink={isAllowDirectLink}
                              isDirectLinkEnabled={isDirectLinkEnabled}
                              setAllowDirectLink={setAllowDirectLink}
                            />
                          );
                        })
                      ) : (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={10}>
                            <div
                              style={{
                                textAlign: "center",
                                height: "150px",
                                marginTop: "90px",
                                width: "100%",
                              }}
                            >
                              No Record Found
                            </div>
                          </TableCell>
                        </TableRow>
                      )}
                      {/* {emptyRows > 0 && (
                        <TableRow
                          style={{
                            height: (dense ? 33 : 53) * emptyRows,
                          }}
                        >
                          <TableCell colSpan={6} />
                        </TableRow>
                      )} */}
                    </TableBody>
                  </Table>
                </TableContainer>

                <TablePagination
                  rowsPerPageOptions={[10, 25, 50]}
                  component="div"
                  count={scansData.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  onPageChange={handleChangePage}
                  onRowsPerPageChange={handleChangeRowsPerPage}
                />
              </Paper>
            ) : (
              <div style={{ textAlign: 'center', margin: "200px" }}>
                <Typography>No scans found please refresh</Typography>
              </div>
            )}
          </>
        ) : (
          <div style={{ textAlign: "center", height: "60vh" }}>
            <SimpleLoader text={""} height="100%" size={50} />
            {/* <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" /> */}
          </div>
        )}
      </Box>

      {isShowDeleteScan ? (
        <ScanDeleteSure
          open={isShowDeleteScan}
          onDelete={() => onDeleteScans()}
          isLoading={isDeleteLoading}
          testId={"test"}
          title={"Are you sure?"}
          discription={isPatientDelete ? "Removing the patient will remove the doctors, and the linked reports." :
            isSentStudy ? "This will only delete the patient, you will have to delete again to remove the study."
              : "This action cannot be undone once deleted."


          }
          closeHandler={() => {
            setShowDeleteScan(false);
          }}
        />
      ) : (
        ""
      )}
    </Container>
  );
}
