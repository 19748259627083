import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  getPatientProfileAction
} from "../actions/patients.action";

// Define the shape of your state
interface PatientProfileState {
  isLoading: boolean | null;
  patientProfile: PatientProfile | null;
}

interface PatientProfile {
  dob: string;
  name: string;
  email: string;
  phone_number: string;
  gender: string;
}

const initialState: PatientProfileState = {
  isLoading: null,
  patientProfile: null,
};

const patientProfileSlice = createSlice({
  name: "patient-profile",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getPatientProfileAction.pending, (state) => {
      state.isLoading = true;
    });

    builder.addCase(getPatientProfileAction.fulfilled, (state, { payload }) => {
      state.isLoading = false;
      if(payload?.data?.status === 'success') {
        state.patientProfile = payload?.data?.data;
      }
    });

    builder.addCase(getPatientProfileAction.rejected, (state) => {
      state.isLoading = false;
      state.patientProfile = null;
    });
  },
});

export const patientProfileSelector = (state: { patientProfile: PatientProfileState }) => state.patientProfile.patientProfile;

export default patientProfileSlice.reducer;
