import React from "react";

const ActionBtn = ({label, backgroundColor, imgSrc,onClick}) => {
  return (
    <button
      style={{
        backgroundColor: backgroundColor,
        borderRadius: "5px",
        padding: "5px",
        height: "auto",
        border:`1px solid ${backgroundColor}`,
        cursor:"pointer"
      }}
      onClick={onClick}
    >
      <img src={imgSrc} /> 
      {label}
    </button>
  );
};

export default ActionBtn;
